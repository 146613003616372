<!-- <mat-toolbar mfmSecondHeader>
  <div class="mfm-second-header_left">
    <mfm-context-header></mfm-context-header>
  </div>
  <div class="mfm-second-header_center"></div>
  <div class="mfm-second-header_right"></div>
</mat-toolbar> -->

@if (navigateBackLinkInfo$ | async; as navigateBackLinkInfo) {
  <a
    [routerLink]="navigateBackLinkInfo.routerLink"
    class="phar-back-link">
    <mat-icon svgIcon="arrow-left"></mat-icon>
    {{ navigateBackLinkInfo.title }}
  </a>
}


<div class="d-flex flex-column">
  <phar-second-level-menu [links]="secondLevelLinks"></phar-second-level-menu>
  <main class="context-mainContent scroll-holder">
    <router-outlet></router-outlet>
  </main>
</div>
