import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input, OnChanges,
  OnInit,
  Output, SimpleChanges
} from '@angular/core';
import { DndDirective } from '../directives/dnd.directive';
import { AppState } from '../../store/models/app.state';
import { Store } from '@ngrx/store';
import { errorPopup } from '../../store/actions/ui.actions';
import { NgClass, NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'phar-file-upload',
  templateUrl: 'file-upload.component.html',
  styleUrl: 'file-upload.component.scss',
  standalone: true,
  imports: [
    DndDirective,
    NgOptimizedImage,
    NgClass
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FileUploadComponent implements OnChanges {

  @Input() acceptedFiles: string[] = ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'];
  @Input() maxSizeInMegabytes = 2;
  @Input() disabled = false;
  @Input() title = 'Drag and drop a logotype or Browse Files';
  @Input() subtitle = 'Recommended size: 240px Width and 48px Height';
  @Input() maxSizeInBytes = this.maxSizeInMegabytes * 1024 * 1024;
  @Input() imageSrc: string;
  @Output() afterUpload: EventEmitter<any> = new EventEmitter();
  uploadedImage: string;

  constructor(private store: Store<AppState>, private cd: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.hasOwnProperty('imageSrc') && !changes.imageSrc.isFirstChange()) {
      this.uploadedImage = null;
    }
  }

  fileChange(fileList: FileList): void {
    if (this.disabled) {
      return;
    }
    if (fileList.length === 0) {
      return;
    }

    const file: File = fileList[0];
    if (!this.acceptedFiles.includes(file.type)) {
      this.store.dispatch(errorPopup({ error: `You can upload only ${this.acceptedFiles.join()} files` }));
      return;
    }

    if (file.size > this.maxSizeInBytes) {
      this.store.dispatch(errorPopup({ error: `You can upload only files under ${this.maxSizeInMegabytes} MB` }));
      return;
    }

    const fileReader = new FileReader();
    fileReader.addEventListener('load', ({ target }) => {
      this.uploadedImage = target.result as string;
      this.afterUpload.emit(target.result);
      this.cd.detectChanges();
    });
    fileReader.readAsDataURL(file);
  }

}
