import { Injectable } from "@angular/core";
import { PharHttp } from "../core/http/http-client.service";
import { AppConfig } from "../core/config/app.config";
import { Store } from "@ngrx/store";
import { AppState, getState } from "../store/models/app.state";
import { Observable } from "rxjs";
import { ProjectEventModel } from "./project-event.model";

@Injectable()
export class EventService {
  constructor(
    private http: PharHttp,
    private config: AppConfig,
    private store: Store<AppState>
  ) {
  }

  updateProjectEvent(projectEvent: ProjectEventModel) {
    const userId = getState(this.store).user.profile.userId
    projectEvent.userIdUpdated = userId;
    projectEvent.event.userIdUpdated = userId;
    return this.http.put(this.config.buildUri('projectEvent') + '/UpdateProjectEventAndEvent', projectEvent);
  }

  createProjectEvent(projectEvent: ProjectEventModel) {
    const projectEventBody = { ...projectEvent };
    delete projectEventBody.id;
    delete projectEventBody.eventId;
    delete projectEventBody.orderPriority; // TODO: remove when priority requerments are completed
    delete projectEventBody.project;
    projectEventBody.organizationId = getState(this.store).user.profile.organizationId;
    projectEventBody.userIdCreated = getState(this.store).user.profile.userId;

    delete projectEventBody.event.id;
    projectEventBody.event.organizationId = getState(this.store).user.profile.organizationId;
    projectEventBody.event.userIdCreated = getState(this.store).user.profile.userId;

    return this.http.post(this.config.buildUri('projectEvent') + '/CreateProjectEventAndEvent?isAmend=false', projectEventBody);
  }

  deleteProjectEvent(id: number): Observable<any> {
    return this.http.delete(this.config.buildUri('projectEvent') + '/ProjectEvent/?projectEventId=' + id);
  }

  getProjectEventListByProjectId(id: number): Observable<ProjectEventModel[]> {
    return this.http.get(this.config.buildUri('projectEvent') + '/GetProjectEventsByProjectId/?projectId=' + id);
  }
  getProjectEventsCommentsCounters(): Observable<any[]> {
    return this.http.get(this.config.buildUri('projectEventComments') + `/ProjectEventCommentCount`)
  }
}
