import { Component, OnInit, ViewChild } from '@angular/core';
import { AppState, getState } from '../../store/models/app.state';
import { Store } from '@ngrx/store';
import { ADHOC_EVENTS_TYPES } from '../../core/config/app.constants';
import { Observable } from 'rxjs';
import { checkCurrentProjectLockedState } from '../../project/store/project.actions';
import { EventModel } from '../event.model';
import { selectProjectEventFromListByEventId } from '../store/event.state';
import { map, take } from 'rxjs/operators';
import { ProjectEventModel } from '../project-event.model';
import { createProjectEvent } from '../store/event.actions';
import { ScheduledEventsListComponent } from '../scheduled-events-list/scheduled-events-list.component';
import { AdhocEventsListComponent } from '../adhoc-events-list/adhoc-events-list.component';

export enum EventsEditorTabEnum {
  Properties,
  Notifications,
}

enum EventTab {
  ScheduledEvents,
  AdhocEvents
}

@Component({
  templateUrl: 'events.component.html',
  styleUrls: ['./events.component.scss']
})

export class EventsComponent implements OnInit {
  selectedTab = EventTab.ScheduledEvents
  @ViewChild('scheduledEventsListComponent') scheduledEventsListComponent: ScheduledEventsListComponent
  @ViewChild('adhocEventsListComponent') adhocEventsListComponent: AdhocEventsListComponent
  isLocked$: Observable<boolean>;
  protected readonly EventTab = EventTab;

  constructor(private store: Store<AppState>) {
  }

  setActiveTab(tabId: EventTab): void {
    this.selectedTab = tabId;
  }

  ngOnInit() {

    const selectedEvent = getState(this.store).event.current.projectEvent;
    if (ADHOC_EVENTS_TYPES.includes(selectedEvent?.event?.elementType)) {
      this.setActiveTab(EventTab.AdhocEvents);
    }
    this.isLocked$ = this.store.select(checkCurrentProjectLockedState);
  }

  duplicateEvent(event: EventModel): void {
    this.store.select(selectProjectEventFromListByEventId({ eventId: event.id }))
      .pipe(
        map((projectEvent) => {
          const event: EventModel = projectEvent.event;
          const newEvent: EventModel = {
            ...event,
            id: null,
            eventName: this.generateUniqueEventName(event),
          };

          const newProjectEvent: ProjectEventModel = {
            ...projectEvent,
            id: null,
            eventId: null,
            event: newEvent,
          };

          return newProjectEvent;
        }),
        take(1),
      )
      .subscribe((projectEvent) => {
        this.store.dispatch(createProjectEvent({ projectEvent }));
      });
  }

  add(): void {
    if (this.selectedTab === EventTab.AdhocEvents && this.adhocEventsListComponent) {
      this.adhocEventsListComponent.addNewEvent();
      return;
    }

    if (this.selectedTab === EventTab.ScheduledEvents && this.scheduledEventsListComponent) {
      this.scheduledEventsListComponent.addEvent();
    }
  }

  private generateUniqueEventName(event: EventModel) {
    let newName = event.eventName + ' (copy)';
    const list: EventModel[] = getState(this.store).event.listProjectEvents.map(projectEvent => projectEvent.event)
    while (list.some((item: EventModel) => item.eventName.toLowerCase().trim() === newName.toLowerCase().trim())) {
      newName = newName + ' (copy)';
    }
    return newName;
  }
}
