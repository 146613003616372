<div>
  @for (notification of notifications$ | async; track notification?.id) {
    <phar-event-notification
      class="my-3"
      [eventNotification]="notification"
      [disabled]="disabled">
      <span notificationTitle>
        @if (notification.unit) {
          {{ '' | pharTransformTimeUnit: notification.unit: '':  '': true }} = {{ notification.number }} -
        }
        {{ notification.specificTime }}
      </span>
    </phar-event-notification>
  }

  @if (newNotification) {
    <phar-event-notification
      class="my-3"
      [eventNotification]="newNotification"
      [disabled]="disabled"
      (createNewNotification)="createNewNotification($event)"
      (deleteNewNotification)="newNotification = null">
      <span notificationTitle>New notification</span>
    </phar-event-notification>
  }

  @if (!disabled) {
    <button mat-raised-button (click)="addNotification()">
      Add notification
    </button>
  }
</div>
