<div
  [class.assessment-content_no-left-bar]="false"
  [class.assessment-content_no-right-bar]="true"
  class="assessment-content">
  <aside class="assessment-content_leftbar">
    <div class="assessment-content_leftbar_search-title">
      Search
      <div class="phar-icon-button filter ms-auto" [matMenuTriggerFor]="filters" pharTooltip="Filters">
        <mat-icon svgIcon="filter"></mat-icon>
      </div>

      <mat-menu #filters="matMenu" [overlapTrigger]="false">
        <button mat-menu-item
                (click)="addFilterByType()"
                [disabled]="filterByType()"
        >
          <span>Form type</span>
        </button>


      </mat-menu>

    </div>
    <mat-form-field class="assessment-content_leftbar_search-field">
      <div class="d-flex">
        <mat-icon class="me-2" matTextPrefix svgIcon="search"></mat-icon>
        <input placeholder="Search Assessments" matInput (input)="onSearchInputChange($event)"/>
      </div>

    </mat-form-field>

    @if (filterByType()) {
      <div class="assessment-content_leftbar_search-field mb-3">
        <phar-filter-item [filter]="filterByType()" (filterUpdate)="handleFilterUpdate($event)"/>
      </div>
    }
    <div class="assessment-content_leftbar_forms-section">
      <h6 (click)="toggleLastReleasedSection()" class="cursor-pointer">
        Last Released
        <ng-container
          *ngTemplateOutlet="openCloseTmp; context: {state: lastReleasedSectionState}">
        </ng-container>
      </h6>
      <div [@toggleSidebarSection]="lastReleasedSectionState">
        @if ((filteredForms$ | async)  | slice: 0: lastReleasedFormsCount; as lastReleased) {
          <ng-container
            *ngTemplateOutlet="formTemplate; context: { forms: lastReleased}"></ng-container>
        }
      </div>
      @if ((filteredForms$ | async) | slice: lastReleasedFormsCount; as releasedForms) {
        @if (releasedForms.length) {
          <h6 class="cursor-pointer" (click)="toggleLibrarySection()">
            Library
            <ng-container
              *ngTemplateOutlet="openCloseTmp; context: {state: librarySectionState}">
            </ng-container>
          </h6>
          <div [@toggleSidebarSection]="librarySectionState">
            <ng-container *ngTemplateOutlet="formTemplate; context: { forms: releasedForms }"></ng-container>
          </div>
        }
      }
    </div>
  </aside>

  <ng-template #formTemplate let-forms="forms">
    <mat-nav-list cdkDropList
                  [cdkDropListSortingDisabled]="true"
                  [cdkDropListConnectedTo]="connectedTo"
                  [cdkDropListData]="forms"
                  [id]="listName"
    >
      @for (form of forms; track form.id) {
        <div
          cdkDrag
          [cdkDragData]="form"
          [pharTooltipDisabled]="formLeftBlock.scrollWidth <= formLeftBlock.clientWidth"
          [pharTooltip]="form.name"
          class="form-template cursor-pointer"
          pharTooltipPosition="above">
          <div #formLeftBlock class="form-template_left-block">
            <mat-icon class="form-template_left-block_form-icon" svgIcon="forms"></mat-icon>
            <span class="form-template_name">{{ form.name }}</span>
          </div>

          <div class="form-template_right-block">
            <span>v{{ form.version }}</span>
            <button (click)="openFormPreviewDialog(form)" class="eye-button" mat-icon-button>
              <mat-icon svgIcon="eye"></mat-icon>
            </button>
          </div>
          <div *cdkDragPlaceholder class="drop-placeholder">
          </div>
        </div>

      }
    </mat-nav-list>

  </ng-template>


  <ng-template #openCloseTmp let-state="state">
    @if (state === toggleSidebarSectionState.Open) {
      <mat-icon
        class="float-right cursor-pointer small-icon"
        svgIcon="arrow-up">
      </mat-icon>
    } @else {
      <mat-icon
        class="float-right cursor-pointer small-icon"
        svgIcon="arrow-right">
      </mat-icon>
    }
  </ng-template>
