import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { Observable } from 'rxjs';
import { AssignModel } from '../assign.model';
import { first, map } from 'rxjs/operators';
import { AppState } from '../../store/models/app.state';
import { Store } from '@ngrx/store';
import { selectAssignListState } from '../store/assign.state';


@Component({
  selector: 'phar-assign-group-editor',
  templateUrl: './assign-group-editor.component.html',
  styleUrls: ['./assign-group-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignGroupEditorComponent implements OnInit, OnChanges {

  @Input() assignment: AssignModel;

  @Output() discard: EventEmitter<void> = new EventEmitter();
  @Output() save: EventEmitter<string> = new EventEmitter<string>();

  form: FormGroup<{ group: FormControl<string> }>;


  constructor(private formBuilder: FormBuilder, private store: Store<AppState>) {
  }

  ngOnInit(): void {
    const groupName = this.assignment?.groupName;
    this.form = this.formBuilder.group({
      group: [groupName, [Validators.required], [uniqueGroupName(this.store.select(selectAssignListState))]]
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('assignment') && !changes.assignment.isFirstChange()) {
      if (changes.assignment.currentValue) {
        this.form.get('group').patchValue(changes.assignment.currentValue.groupName);
      } else {
        this.form.get('group').patchValue('');
      }
    }
  }

  closeEditor(): void {
    this.discard.emit();
  }

  saveGroup(): void {
    if (this.form.invalid) {
      this.form.get('group').markAsTouched();
      return;
    }
    this.save.emit(this.form.get('group').value);
  }

}


function uniqueGroupName(selector: Observable<AssignModel[]>): AsyncValidatorFn {
  const errorMsg = { 'notUniqueName': true }
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return selector.pipe(
      map((data) => {

        if (!data.length) {
          return null;
        }
        const valueToLowerAndTrim = control.value.toLowerCase().trim();
        return !!data.filter(el => el.groupName?.toLowerCase().trim() === valueToLowerAndTrim ||
          el.formName?.toLowerCase().trim() === valueToLowerAndTrim).length ? errorMsg : null

      }),
      first()
    )
  }
}
