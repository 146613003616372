<div id="adhoc-container" [class.editor-opened]="isEditorOpened" #eventsContainer>
  <div id="adhoc-list">
    <ng-content></ng-content>
    <div class="d-flex flex-row align-items-center w-100">
      <div class="w-100 align-items-center">
        <mat-label class="me-auto px-4 font-weight-bold font-size-lg">Adhoc events</mat-label>
      </div>
      @if (!isLocked) {
        <div class="d-flex justify-content-end">
          <mat-toolbar class="background-transparent px-4">
            <phar-list-column-selection
              class="ms-3"
              [columns]="config.columns"
              [listComponent]="listViewComponent"
              (columnsSelectionChange)="columnsSelectionChange($event)">
            </phar-list-column-selection>
          </mat-toolbar>
        </div>

      }
    </div>

    <div class="px-3 d-flex flex-column">
      <phar-draggable-list-view draggingDisabled="true"
                                #listViewComponent
                                [config]="config"
                                [dataSource]="adhocEventsList$ | async"
                                [isRowSelectable]="false"
                                [noDataMessage]="'Please click Add above on the right to start creating adhoc events.'"
                                [selectedRecordId]="selectedEventId"
                                (afterSelect)="handleEdit($event.dataItem.id)"/>

    </div>

  </div>
  @if (isEditorOpened) {
    <div class="widgets-container_content_rightbar d-flex flex-column px-0 position-relative" id="adhoc-editor">
      <div
        class="resize-handle"
        [class.resizing]="rightBarResizingEvent.isResizing"
        (mousedown)="startRightBarResizing($event)"
      ></div>
      <button (click)="closeEditor()" class="events-editor-close-icon" mat-icon-button>
        <mat-icon class="button-block_small-icon" svgIcon="close"></mat-icon>
      </button>
      <div class="d-flex justify-content-center p-3">
        <button
          class="left-switch-button cursor-pointer"
          mat-button
          [ngClass]="{'active-switch-button': activeEditorTab === EventsEditorTabEnum.Properties}"
          (click)="setActiveEditorTab(EventsEditorTabEnum.Properties)">
          Properties
        </button>
        <button
          class="right-switch-button cursor-pointer"
          mat-button
          [ngClass]="{'active-switch-button': activeEditorTab === EventsEditorTabEnum.Notifications}"
          [disabled]="!selectedEventId"
          (click)="setActiveEditorTab(EventsEditorTabEnum.Notifications)">
          Notifications ({{ numberOfEventNotifications$ | async }})
        </button>
      </div>
      <div class="flex-1 overflow-auto px-2">
        @switch (activeEditorTab) {
          @case (EventsEditorTabEnum.Properties) {
            <phar-adhoc-event-editor
              [event]="selectedEvent$ | async"
              [disabled]="isLocked"
              (save)="handleSave()"
              (discard)="closeEditor()">
            </phar-adhoc-event-editor>
          }
          @case (EventsEditorTabEnum.Notifications) {
            <phar-event-notifications-list
              [event]="selectedEvent$ | async"
              [disabled]="isLocked"
            ></phar-event-notifications-list>
          }
        }
      </div>
    </div>
  }
</div>


<ng-template #elementTypeTemplate let-element>
  <phar-event-type [elementType]="element.elementType"></phar-event-type>
</ng-template>

<ng-template #actionsTemplate let-element>
  @if (!isLocked) {
    <button class="ms-auto list-card_more" mat-icon-button [matMenuTriggerFor]="actionsMenu"
            (click)="$event.stopImmediatePropagation()">
      <mat-icon class="more">more_vert</mat-icon>
    </button>
    <mat-menu #actionsMenu="matMenu" [overlapTrigger]="false">
      @for (button of rowActionBtns; track $index) {
        <!--        <ng-template [ngxPermissionsOnly]="button.permissions">-->
        <button class="button-with-icon" mat-menu-item
                [disabled]="button.disabled"
                (click)="actionHandler({eventName: button.eventName, dataItem: element })">
          @if (button.icon) {
            <mat-icon [svgIcon]="button.icon"></mat-icon>
          }
          <span>{{ button.title }}</span>

        </button>
        <!--        </ng-template>-->
      }
    </mat-menu>
  }


</ng-template>

