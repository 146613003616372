import {
  AfterViewInit,
  Component, effect,
  inject,
  Injector,
  OnDestroy,
  OnInit, signal,
  TemplateRef,
  ViewChild, WritableSignal
} from '@angular/core';

import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';

import { AssignGroupModel, AssignModel } from '../assign.model';
import { AppState, getState } from '../../store/models/app.state';
import {
  deleteAssign,
  getAssignsByProjectId, loadAssignmentsCommentsCounters,
  resetAssignList,
  updateAssignOrder,
  updateCurrentAssign
} from '../store/assign.actions';
import { CardModel, CardType } from '../../shared/card/card.model';
import { CardService } from '../../shared/card/card.service';
import { PharConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog-service.service';
import { UtilsService } from '../../core/utils.service';
import { ListViewColumnModel } from '../../shared/list-view/list-view-column.model';
import { DraggableListViewConfigModel } from '../../shared/draggable-list-view/draggable-list-view-model';
import { FieldType } from '../../shared/draggable-list-view/models/group-model';
import { AssignTypeEnum } from '../assign-type.enum';
import { errorPopup, loading, messagePopup } from '../../store/actions/ui.actions';
import { FormModel } from '../../form/form.model';
import { AssignService } from '../assign.service';
import { DraggableListViewComponent } from '../../shared/draggable-list-view/draggable-list-view.component';
import { checkCurrentProjectLockedState } from '../../project/store/project.actions';
import { selectAssignCommentsCounter, selectAssignListState } from '../store/assign.state';
import { cloneDeep } from 'lodash-es';
import { ListCardView } from '../../shared/card-list-switcher/card-list-switcher.component';
import { BaseComponent } from '../../shared/base.class';
import {
  ASSIGN_LIST_COLUMN_CONFIG,
  EMPTY_ASSIGNMENT,
  AssignItemActions,
  ASSESSMENT_ACTIONS
} from './assign-list.configs';
import { PreviewFormQuestionsDialogComponent } from '../../form/preview-form-questions-dialog/preview-form-questions-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ListFilterManager } from '../../shared/list-filter/list-filter-manager.class';
import { IListFilter, IListFilterDisplayOption, ListFilter } from '../../shared/list-filter/list-filter.interface';
import { FilterType } from '../../shared/list-filter/filter-item.interface';
import { AssignCommentsDialogComponent } from '../assign-comments/assign-comments-dialog.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { COMMENTS_DIALOG_CONFIG } from '../../shared/entity-comments/entity-comment-base/base-comments-component.config';


@Component({
  templateUrl: './assign-list.component.html',
  styleUrls: ['./assign-list.component.scss']
})
export class AssignListComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('createdAtTemplate', { static: true }) createdAtTemplate: TemplateRef<any>;
  @ViewChild('dragIconTemplate', { static: true }) dragIconTemplate: TemplateRef<any>;
  @ViewChild('expandIconTemplate', { static: true }) expandIconTemplate: TemplateRef<any>;
  @ViewChild('assignmentTypeTemplate', { static: true }) assignmentTypeTemplate: TemplateRef<any>;
  @ViewChild('formNameTemplate', { static: true }) formNameTemplate: TemplateRef<any>;
  @ViewChild('actionsTemplate', { static: true }) actionsTemplate: TemplateRef<any>;
  @ViewChild('draggableListViewComponent') draggableListViewComponent: DraggableListViewComponent;
  AssignType = AssignTypeEnum;
  groupEditorOpened$: Observable<boolean>;
  FieldType = FieldType;
  list$: Observable<CardModel<AssignModel>[]>;
  injector = inject(Injector);
  store: Store<AppState> = inject(Store);
  listView$: Observable<AssignModel[]>;
  listViewSignal = toSignal(this.store.select(state => state.assign.list), {
    injector: this.injector
  });
  projectId: number;
  header$: Observable<boolean>;
  selectedAssignment: AssignModel;
  view: ListCardView = ListCardView.List;
  isLocked$: Observable<boolean>;
  assessmentsContainer = 'assessmentsContainer';
  config: DraggableListViewConfigModel = ASSIGN_LIST_COLUMN_CONFIG;
  lisFilterManager = new ListFilterManager();
  utilsService: UtilsService = inject(UtilsService);

  idClassMap: WritableSignal<{ [key: string]: string }> = signal({});
  initiallyExpandedItems: WritableSignal<{ [key: string]: boolean }> = signal({});
  commentsCounter = toSignal(this.store.select(selectAssignCommentsCounter), { injector: this.injector })
  filterableFields: IListFilterDisplayOption[] = [
    {
      field: 'assignmentType',
      title: 'Type',
      label: 'Type',
      type: FilterType.Dropdown,
      options: {
        data: of([
          {
            label: 'eClinRO',
            id: this.AssignType.Questionnaire,
          },
          {
            label: 'ePRO',
            id: this.AssignType.DataCapture,
          },
          // {
          //   label: 'eObsRO',
          //   id: this.AssignType.EObsRO,
          // },
          // {
          //   label: 'Informed Consent',
          //   id: this.AssignType.InformedConsent,
          // },
          // {
          //   label: 'Group',
          //   id: this.AssignType.AssignGroupEntity,
          // },
        ])
      }

    }
  ];
  rowAssessmentActions = ASSESSMENT_ACTIONS;
  rowGroupActions = [
    {
      title: 'Edit',
      eventName: AssignItemActions.Edit,
      icon: 'edit'
    },
    ...ASSESSMENT_ACTIONS,
  ];
  private readonly _groupEditorOpened$: Subject<boolean> = new Subject<boolean>();
  private readonly emptyAssignment: AssignModel = EMPTY_ASSIGNMENT;

  constructor(
    private assignService: AssignService,
    private confirmationService: PharConfirmDialogService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    protected cardService: CardService,
  ) {
    super();
    this.groupEditorOpened$ = this._groupEditorOpened$.asObservable();
  }

  ngOnInit(): void {
    this.initObservables();
    this.initListeners();
    this.checkForHighlightedRow();
  }

  ngAfterViewInit(): void {
    this.handleColumnsConfig();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.store.dispatch(resetAssignList());
  }


  assignForm(form: FormModel, orderPriority?: number, parentId?: number, reorderData?: any): void {
    const assignment: AssignModel = {
      ...this.emptyAssignment,
      id: 0,
      formId: form.id,
      assignmentType: form.type,
      projectId: this.projectId,
      parentId,
      orderPriority,
    };

    if (form.formDuplicateId) {
      const duplicatedForm = getState(this.store).form.list.find(f => f.id === form.formDuplicateId);
      let text = ''
      if (duplicatedForm) {
        text = `This form is a copy of <b> ${duplicatedForm.name}</b>. Do you want to continue?`
      } else {
        text = `This form is a copy and the form parent <b>isn't released</b> already. Do you want to continue?`
      }
      this.confirmationService.openConfirmDialog(text, 'Warning', 'Yes', 'No').pipe(
        take(1),
        filter(result => !!result)
      ).subscribe({
        next: () => {
          this.createUpdateAssignment(assignment, false, reorderData);
        }
      })
      return;
    }

    this.createUpdateAssignment(assignment, false, reorderData);

  }


  addGroup(): void {
    this.selectedAssignment = null;
    this._groupEditorOpened$.next(true);
  }

  openGroupEditor(): void {
    this._groupEditorOpened$.next(true);
  }

  closeGroupEditor(): void {
    this._groupEditorOpened$.next(false);
    this.selectedAssignment = null;
  }

  saveGroup(groupName: string): void {
    const isUpdate = !!this.selectedAssignment?.id;

    const assignment: AssignModel = isUpdate
      ? {
        ...this.selectedAssignment,
        groupName,
      }
      : {
        ...this.emptyAssignment,
        id: 0,
        groupName,
        projectId: this.projectId,
        assignmentType: AssignTypeEnum.AssignGroupEntity,
      };

    this.createUpdateAssignment(assignment, isUpdate);
    this._groupEditorOpened$.next(false);
  }


  actionHandler($event: { eventName: string, dataItem: AssignGroupModel }): void {
    switch ($event.eventName) {
      case AssignItemActions.Delete:
        $event.dataItem.type === FieldType.Group ? this.openDeleteGroupAssignment($event) : this.openDeleteSingleAssignment($event);
        break;
      case AssignItemActions.Edit:
        if ($event.dataItem.type === FieldType.Group) {
          this.store.dispatch(updateCurrentAssign({ assign: $event.dataItem as AssignModel }));
          this.selectedAssignment = $event.dataItem as AssignModel;
          this.openGroupEditor();
        }
        break;

      // case AssignItemActions.Duplicate:
      //   this.duplicateActionHandler($event.dataItem);
      //   break;
      case AssignItemActions.Comments:
        this.openAssignmentComments($event.dataItem);
        break;
      case AssignItemActions.Preview:
        this.dialog.open(PreviewFormQuestionsDialogComponent, {
          width: '75%',
          maxWidth: '880px',
          height: '90%',
          data: {
            form: { id: $event.dataItem.formId },
          }
        });
        break;
    }
  }

  openAssignmentComments(assignment: AssignGroupModel): void {
    this.dialog.open(AssignCommentsDialogComponent, {
      data: {
        assignmentId: assignment.id
      },
      ...COMMENTS_DIALOG_CONFIG
    }).afterClosed().pipe(
      filter(shouldUpdateCounters => shouldUpdateCounters),
      take(1)
    ).subscribe({
      next: () => {
        this.store.dispatch(loadAssignmentsCommentsCounters());

      }
    })
  }


  onReorder({ reorderData }: { reorderData: any[] }) {
    this.store.dispatch(updateAssignOrder({
      items: reorderData,
    }));
  }

  handleElementAdded(data: { item: any, index: number, parentId: number | undefined, reorderData: any[] }): void {
    this.assignForm(data.item, data.index, data.parentId, data.reorderData);
  }

  handleFilterChange(data: { filter: IListFilter, mainFilter: boolean }): void {
    this.lisFilterManager.createFilter(data);
  }

  handleFilterUpdate(data: { action: 'update' | 'delete', filter: ListFilter | ListFilter[] }): void {
    if (data.action === 'update') {
      this.lisFilterManager.updateFilter(data.filter);
    } else {
      this.lisFilterManager.removeFilter(data.filter);
    }
  }

  private createUpdateAssignment(assignment: AssignModel, isUpdate = false, reorderData?: any[]): Observable<AssignModel> {
    this.store.dispatch(loading(true));
    const errorMsg = isUpdate ? 'Assignment update failed' : 'Assignment failed';
    const successMsg = isUpdate ? 'Assignment update success' : 'Assignment success';
    const createUpdateResponse$ = new Subject<AssignModel | null>();

    this.store.select(selectAssignListState)
      .pipe(
        map((list) => list.filter(item => !item.parentId).length),
        map((orderPriority) => {
          return typeof assignment.orderPriority === 'number' && !Number.isNaN(assignment.orderPriority)
            ? assignment
            : {
              ...assignment,
              orderPriority,
            };
        }),
        switchMap((assignment) => {
          if (isUpdate) {
            return this.assignService.updateAssign([assignment])
          }

          return this.assignService.createAssignment(assignment);
        }),
        take(1),
      )
      .subscribe({
        next: (res: AssignModel) => {
          this.store.dispatch(getAssignsByProjectId({ projectId: this.projectId }));
          this.store.dispatch(messagePopup({ message: successMsg }));
          this.store.dispatch(loading(false));
          createUpdateResponse$.next(res);
          // in case we add element to a certain order we need to reorder all other elements
          if (reorderData) {
            this.onReorder({ reorderData });
          }
        },
        error: () => {
          this.store.dispatch(errorPopup({ error: errorMsg }));
          this.store.dispatch(loading(false));
          createUpdateResponse$.next(null);
        }
      });

    return createUpdateResponse$;
  }

  private openDeleteSingleAssignment($event: { eventName: string, dataItem: AssignGroupModel }) {
    this.confirmationService.openConfirmDialog('Do you want to remove ' + $event.dataItem.formName + '?').pipe(
      take(1),
      filter(result => result !== undefined)
    )
      .subscribe(result => {
        if (result) {
          this.store.dispatch(deleteAssign({ id: $event.dataItem.id, deleteGroup: true }))
          this.afterDeleteHandler($event.dataItem.id);
        }
      });
  }

  private openDeleteGroupAssignment($event: { eventName: string, dataItem: AssignGroupModel }) {
    this.confirmationService.openConfirmDialog(
      `Would you like to delete the group and all related assessments or to ungroup the assessments?` + $event.dataItem.formName + '?',
      '',
      'Delete All',
      'Ungroup'
    ).pipe(
      take(1),
      filter(result => result !== undefined) // undefined act as cancel
    ).subscribe(result => {
      const deleteGroupWithChildAssesments = !!result;
      this.store.dispatch(deleteAssign({
        id: $event.dataItem.id,
        deleteGroup: deleteGroupWithChildAssesments
      }))
      this.afterDeleteHandler($event.dataItem.id);
    });
  }

  private afterDeleteHandler(deleteId: number): void {
    if (this.selectedAssignment && (this.selectedAssignment.id === deleteId)) {
      this.closeGroupEditor();
    }
  }

  private generateNewGroupName(group: AssignModel): string {
    let newGroupName = group.groupName + ' (copy)';
    const list: AssignModel[] = getState(this.store).assign.list;

    while (list.filter(item => !!item.groupName)
      .some(item => item.groupName.toLowerCase().trim() === newGroupName.toLowerCase().trim())) {
      newGroupName = newGroupName + ' (copy)';
    }
    return newGroupName;
  }

  // private duplicateActionHandler(originalAssign: AssignGroupModel): void {
  //   const isGroup = originalAssign.type === FieldType.Group;
  //   const orderPriority = originalAssign.orderPriority + 1;
  //   const newAssignment: AssignModel = isGroup
  //     ? {
  //       ...originalAssign.original,
  //       id: 0,
  //       orderPriority,
  //       groupName: this.generateNewGroupName(originalAssign.original),
  //     }
  //     : {
  //       ...originalAssign.original,
  //       id: 0,
  //       orderPriority,
  //     };
  //
  //
  //   this.store.select(selectAssignListState)
  //     .pipe(
  //       map((list) => {
  //         if (originalAssign.parentId) {
  //           return list.filter(assign => assign.parentId === originalAssign.parentId);
  //         }
  //
  //         return list.filter(assign => !assign.parentId);
  //       }),
  //       map((list) => list.map((assign) => {
  //         if (assign.orderPriority >= orderPriority) {
  //           return {
  //             ...assign,
  //             orderPriority: assign.orderPriority + 1,
  //           };
  //         }
  //
  //         return assign;
  //       })),
  //       switchMap((reorderData) => this.createUpdateAssignment(newAssignment, false, reorderData)),
  //       take(1),
  //     )
  //     .subscribe((duplicatedAssignment) => {
  //       if (isGroup && duplicatedAssignment) {
  //         this.duplicateGroupChildren(originalAssign.id, duplicatedAssignment.id);
  //       }
  //     });
  // }

  // private duplicateGroupChildren(groupId: number, newGroupId: number): void {
  //   this.store.select(selectAssignListState)
  //     .pipe(
  //       take(1),
  //       map((list: AssignModel[]) => {
  //         return list.filter((assignment) => assignment.parentId === groupId);
  //       }),
  //       map((groupChildren: AssignModel[]) => {
  //         return sortBy(groupChildren, (assignment) => assignment.orderPriority)
  //           .map((assignment, index) => ({
  //             ...assignment,
  //             parentId: newGroupId,
  //             orderPriority: index,
  //           }));
  //       }),
  //       filter((newGroupChildren: AssignModel[]) => !!newGroupChildren.length),
  //       switchMap((newGroupChildren: AssignModel[]) => {
  //         return this.assignService.createAssignments(newGroupChildren);
  //       }),
  //       take(1),
  //     )
  //     .subscribe({
  //       next: () => {
  //         this.store.dispatch(getAssignsByProjectId({ projectId: this.projectId }));
  //       },
  //       error: () => {
  //         this.store.dispatch(errorPopup({ error: 'Failed to duplicate elements in group' }));
  //       },
  //     });
  // }

  private initListeners(): void {
    this.store.pipe(
      select(state => state.project.current.project.id),
      filter(id => !!id),
      takeUntil(this.destroy$)
    ).subscribe(id => {
      this.projectId = id;
      this.store.dispatch(getAssignsByProjectId({ projectId: id }));
      // this.store.dispatch(getGroups());
    });
  }

  private initObservables(): void {
    this.header$ = this.store.select(state => state.ui.header);
    this.isLocked$ = this.store.select(checkCurrentProjectLockedState);
    this.list$ = this.store.pipe(
      select(state => state.assign.list),
      map(assigns => {
          return assigns.map(a =>
            ({
              card: {
                settings: this.cardService.mapCardSettings(a?.settings?.cardSettings),
                date: new Date(a.createdAt),
                type: CardType.Assignment,
                name: a.formName
              }, original: a
            }));
        }
      ));
    this.listView$ = this.store.select(state => state.assign.list).pipe(
      map((assignList) => {
        return assignList.map((assign: AssignModel) => {
          const copy = { ...assign };
          copy['original'] = { ...assign };
          return copy;
        });
      }),
      switchMap((list: AssignModel[]) => {
        return this.lisFilterManager.filters$.pipe(
          map((filters: ListFilter[]) => this.filterAssignments(filters, list))
        )
      }),
    );
  }

  private handleColumnsConfig(): void {
    const templates = {
      createdAt: this.createdAtTemplate,
      drag: this.dragIconTemplate,
      expand: this.expandIconTemplate,
      assignmentType: this.assignmentTypeTemplate,
      formName: this.formNameTemplate,
      updatedAt: this.actionsTemplate
    };
    this.config.columns = this.utilsService.setColumnTemplate<ListViewColumnModel>(this.config.columns, templates, 'field');
  }

  private filterAssignments(filters: ListFilter[], list: AssignModel[]): AssignModel[] {
    if (!filters.length) {
      return list;
    }
    let filtered = cloneDeep(list);
    for (const filter of filters) {
      filtered = this.filterByField(filter, filtered);
    }
    return filtered;

  }


  private filterByField(filter: ListFilter, list: AssignModel[]): AssignModel[] {
    let filtered: AssignModel[] = [];
    const { value } = filter;
    if (value === null || value === undefined || value === '') {
      return list;
    }
    filtered = list.filter(item => {
      const { formName, groupName, assignmentType } = item;
      if (filter.field === 'formName') {
        const fieldToCheck = formName || groupName || '';
        let val = this.utilsService.trimToLower(value);
        return this.utilsService.trimToLower(fieldToCheck).includes(val);
      } else {
        return assignmentType === value;
      }

    });

    const parents: AssignModel[] = [];
    let children: AssignModel[] = [];
    filtered.forEach((item, index, arr: AssignModel[]) => {
      // if item has a parent and the parent is not presented in the filteredArr
      if (item.parentId && !(arr.find(arrItem => arrItem.id === arrItem.parentId)) && !parents.find(arrItem => arrItem.id === item.parentId)) {
        parents.push(list.find(arrItem => arrItem.id === item.parentId))
      }
      // if its group, children should be there as well
      if (!item.parentId && !item.formId) {
        let itemChildren = list
          .filter(assign => assign.parentId === item.id && !arr.find(child => child.id === assign.id));
        children = [...children, ...itemChildren];
      }

    })
    return [...filtered, ...parents, ...children];
  }

  private checkForHighlightedRow(): void {
    const id = this.activatedRoute.snapshot.queryParamMap.get('id');
    if (!id) {
      return;
    }

    const highlightEffect = effect(() => {
      const assignment: AssignModel = this.listViewSignal().find(x => x.id === Number(id));
      if (!assignment) {
        return;
      }
      this.idClassMap.set({ [id]: 'highlighted' });
      if (assignment.parentId) {
        //expand parent group
        this.initiallyExpandedItems.set({ [assignment.parentId]: true });
      }

      highlightEffect.destroy();
    }, {
      manualCleanup: true,
      injector: this.injector,
      allowSignalWrites: true,
    })

  }

  // changeView($event: ListCardView): void {
  //   this.view = $event;
  // }
  // goToCreateNew(): void {
  // const dialogRef = this.dialog.open(AssignFormsComponent, {
  //   width: '980px',
  //   minWidth: 980,
  //   backdropClass: 'assign-form_backdrop',
  //   panelClass: 'assign-form-dialog',
  //   data: {
  //     mode: 'add'
  //   },
  //   autoFocus: false,
  //   restoreFocus: false
  // });
  // dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
  // });
  // }

  // clickHandler($event: { dataItem: GroupModel }): void {
  //   this.store.dispatch(getAssignById({ id: $event.dataItem.id }));
  //   const dialogRef = this.dialog.open(AssignFormsComponent, {
  //     width: '980px',
  //     minWidth: 980,
  //     backdropClass: 'assign-form_backdrop',
  //     panelClass: 'assign-form-dialog',
  //     data: {
  //       mode: 'edit'
  //     },
  //     autoFocus: false,
  //     restoreFocus: false
  //   });
  //   dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
  //   });
  // }
  protected readonly AssignItemActions = AssignItemActions;
}
