import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { Observable, of, switchMap } from 'rxjs';
import { filter, map, shareReplay, take, tap } from 'rxjs/operators';

import { AppState, getState } from '../../store/models/app.state';
import { ProjectModel, ProjectStatusEnum, ProjectTypeEnum } from '../project.model';
import { deleteProject, loadProjectList } from '../store/project.actions';
import {
  errorPopup,
  globalLoading,
  messagePopup,
  removeContextTitle,
  setThirdLevelMenuElements
} from '../../store/actions/ui.actions';
import { CardColor, CardModel, CardType } from '../../shared/card/card.model';
import { CardService } from '../../shared/card/card.service';
import { ListCardView } from '../../shared/card-list-switcher/card-list-switcher.component';
import { ListViewConfigModel } from '../../shared/list-view/list-view-model';
import { PharConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog-service.service';
import { ListViewColumnModel } from '../../shared/list-view/list-view-column.model';
import { UtilsService } from '../../core/utils.service';
import { HeaderService } from '../../layout/phar-header/header.service';
import { DatePipe } from '@angular/common';
import { EntityType } from '../../core/models/entity-type-enum';
import { cloneDeep, orderBy } from 'lodash-es';
import { ProjectActionsEnum } from './project-actions.enum';
import { BaseComponent } from '../../shared/base.class';
import { ProjectService } from '../project.service';
import { selectUserList } from '../../user/store/user.reducer';
import { UserModel } from '../../user/user.model';
import { ListFilterManager } from '../../shared/list-filter/list-filter-manager.class';
import { FilterType } from '../../shared/list-filter/filter-item.interface';
import { IListFilter, IListFilterDisplayOption, ListFilter } from '../../shared/list-filter/list-filter.interface';

@Component({
  templateUrl: './list-projects.component.html',
  styleUrls: ['./list-projects.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListProjectsComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('releaseDateTemplate', { static: true }) releaseDateTemplate: TemplateRef<any>;
  @ViewChild('updatedAtTemplate', { static: true }) updatedAtTemplate: TemplateRef<any>;
  @ViewChild('createdAtTemplate', { static: true }) createdAtTemplate: TemplateRef<any>;
  @ViewChild('projectStatusTemplate', { static: true }) projectStatusTemplate: TemplateRef<any>;
  @ViewChild('projectVersionTemplate', { static: true }) projectVersionTemplate: TemplateRef<any>;
  @ViewChild('actionsTemplate', { static: true }) actionsTemplate: TemplateRef<any>;
  @ViewChild('cardSubtitleTemplate', { static: true }) cardSubtitleTemplate: TemplateRef<any>;
  @ViewChild('projectNameTemplate', { static: true }) projectNameTemplate: TemplateRef<any>;
  @ViewChild('studyIdTemplate', { static: true }) studyIdTemplate: TemplateRef<any>;
  @ViewChild('sponsorTemplate', { static: true }) sponsorTemplate: TemplateRef<any>;

  EntityType = EntityType;
  cardsView$: Observable<CardModel<ProjectModel>[]>;
  listView$: Observable<ProjectModel[]>;
  header: Observable<boolean>;
  userId: number;
  projectType = ProjectTypeEnum;
  customersGroupName = '';
  view: ListCardView = ListCardView.List;
  lisFilterManager = new ListFilterManager();
  noFilteredResultsMsg = 'No results found matching the selected criteria.'
  noItemsMsg = 'Please select Add above to start creating a questionnaire or CRF.';
  projectStatuses$ = of([
    { id: ProjectStatusEnum.Draft, label: 'Draft' },
    { id: ProjectStatusEnum.PendingReview, label: 'Pending review' },
    { id: ProjectStatusEnum.UnderReview, label: 'Under review' },
    { id: ProjectStatusEnum.Released, label: 'Released' },
    { id: ProjectStatusEnum.Amended, label: 'Amended' },
    { id: ProjectStatusEnum.Rejected, label: 'Rejected' },
    // { id: ProjectStatusEnum.Archived, label: 'Archived' }, @TODO NOt available as an option right now
  ]);
  config: ListViewConfigModel = {
    columns: [
      {
        field: 'id',
        title: 'Study ID',
        size: '130px',
        show: true,
        order: true,
        hasTemplate: true,
        template: null,
        selectable: true,
      },
      {
        field: 'name',
        title: 'Study Acronym/Short Name',
        size: '1fr',
        show: true,
        order: true,
        hasTemplate: true,
        template: null,
        selectable: true,
      },
      {
        field: 'datasetId',
        title: 'Sponsor',
        size: '1fr',
        show: true,
        order: true,
        hasTemplate: true,
        selectable: true,
        template: null
      },
      {
        field: 'projectVersion',
        title: 'Version',
        size: '1fr',
        show: false,
        hasTemplate: true,
        selectable: true,
        template: null,
        filter: {
          field: 'projectVersion',
          title: 'Version',
          type: FilterType.String,
          label: 'Version',
        }
      },
      {
        field: 'createdAt',
        title: 'Date created',
        size: '1fr',
        show: false,
        order: true,
        selectable: true,
        template: null,
        hasTemplate: true,
        filter: {
          field: 'createdAt',
          title: 'Date created',
          type: FilterType.DateRange,
          label: 'Created at date range',
        }
      },
      {
        field: 'releaseDate',
        title: 'Approved Date',
        size: '160px',
        show: true,
        order: true,
        hasTemplate: true,
        template: null,
        selectable: true,
        filter: {
          field: 'releaseDate',
          title: 'Approved Date',
          type: FilterType.DateRange,
          label: 'Released date range',
        }
      },

      {
        field: 'updatedAt',
        title: 'Last Modified',
        size: '1fr',
        show: true,
        order: true,
        template: null,
        hasTemplate: true,
        selectable: true,
        filter: {
          field: 'updatedAt',
          title: 'Last modified',
          type: FilterType.DateRange,
          label: 'Last modified at date range',
        }
      },
      {
        field: 'projectStatus',
        title: 'Status',
        size: '80px',
        show: true,
        order: true,
        hasTemplate: true,
        template: null,
        selectable: true,
        filter: {
          field: 'projectStatus',
          title: 'Status',
          type: FilterType.Dropdown,
          label: 'Project Status',
          options: {
            data: this.projectStatuses$,
            multiple: true
          }
        }
      },
      {
        field: 'actions',
        title: 'Actions',
        size: '50px',
        show: true,
        hasTemplate: true,
        template: null
      },
    ],
  };
  orderPredicate$: Observable<string>;
  users$: Observable<UserModel[]>;
  filterableFields: IListFilterDisplayOption[] = [];
  mappedUsers$: Observable<{ id: string | number, label: string }[]>;
  protected readonly ProjectActionsEnum = ProjectActionsEnum;

  constructor(
    private activatedRoute: ActivatedRoute,
    private cardService: CardService,
    private confirmationService: PharConfirmDialogService,
    private datePipe: DatePipe,
    private headerService: HeaderService,
    private router: Router,
    private store: Store<AppState>,
    private utilsService: UtilsService,
    private projectService: ProjectService,
  ) {
    super();
  }

  ngOnInit(): void {

    this.users$ = this.store.select(selectUserList).pipe(shareReplay(1));
    this.mappedUsers$ = this.users$.pipe(
      map((users: UserModel[]) => {
        return users.map((user: UserModel) => {
          return { id: user.userId, label: user.userName };
        })
      })
    )
    // this.store.select(selectCurrentOrganization).pipe(distinctUntilChanged()).subscribe((org) => {
    //   const customersGroup = org.securityGroupDetails.find(organization => organization.groupType === SecurityGroupTypeEnum.Customer);
    //   if (customersGroup) {
    //     this.customersGroupName = customersGroup.value;
    //   }
    // })
    this.store.dispatch(setThirdLevelMenuElements({ menuElements: [] }));
    this.cardsView$ = this.store.pipe(
      select(state => state.project.list),
      switchMap((cards) => {
        return this.lisFilterManager.filters$.pipe(
          map((filters: ListFilter[]) => {
            if (!filters.length) {
              return cards;
            }
            return this.lisFilterManager.filter(cloneDeep(cards));
          })
        )
      }),
      map(projects => {

          return projects.map(project => {

              return {
                card: {
                  name: project.name,
                  type: CardType.Project,
                  settings: this.cardService.mapCardSettings(project?.settings?.cardSettings),
                  date: new Date(project.createdAt),
                  subtitleTemplate: this.cardSubtitleTemplate,

                  color: CardColor.Neutral,
                  status: project.projectStatus,
                }, original: project
              } as CardModel<ProjectModel>;
            }
          );
        }
      )
    );
    this.listView$ = this.store.select(state => state.project.list).pipe(
      map((project) => {
        return orderBy(project, 'createdAt', 'desc').map((p) => {
          const copy = { ...p };
          copy['original'] = { ...p };
          return copy;
        });
      }),
      switchMap((list: ProjectModel[]) => {
        return this.lisFilterManager.filters$.pipe(
          map((filters: ListFilter[]) => {
            if (!filters.length) {
              return list;
            }
            return this.lisFilterManager.filter(cloneDeep(list));
          })
        )
      }),
    );
    this.header = this.store.select(state => state.ui.header);
    this.orderPredicate$ = this.store.select(state => state.ui.sorting);
    this.store.dispatch(globalLoading(true));
    this.store.dispatch(loadProjectList());
    this.userId = getState(this.store).user.profile.userId;
    this.headerService.setHeaderText('Studies');
    this.store.dispatch(removeContextTitle({ position: 1 }));
    this.setupFilterableFields(this.config.columns);
  }

  ngAfterViewInit(): void {

    const templates = {
      releaseDate: this.releaseDateTemplate,
      updatedAt: this.updatedAtTemplate,
      createdAt: this.createdAtTemplate,
      projectStatus: this.projectStatusTemplate,
      projectVersion: this.projectVersionTemplate,
      actions: this.actionsTemplate,
      name: this.projectNameTemplate,
      id: this.studyIdTemplate,
      datasetId: this.sponsorTemplate
    };

    this.config.columns = this.utilsService.setColumnTemplate<ListViewColumnModel>(this.config.columns, templates, 'field');

  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  handleFilterChange(data: { filter: IListFilter, mainFilter: boolean }): void {
    this.lisFilterManager.createFilter(data);
  }

  handleFilterUpdate(data: { action: 'update' | 'delete', filter: ListFilter | ListFilter[] }): void {
    if (data.action === 'update') {
      this.lisFilterManager.updateFilter(data.filter);
    } else {
      this.lisFilterManager.removeFilter(data.filter);
    }
  }

  changeView($event: ListCardView): void {
    this.view = $event;
  }

  goToCreateNew(): void {
    this.router.navigate(['../new'], { relativeTo: this.activatedRoute });
  }

  actionHandler(event: { eventName: ProjectActionsEnum, dataItem: ProjectModel }): void {
    switch (event.eventName) {
      case ProjectActionsEnum.Delete:
        this.deleteCard(event.dataItem);
        break;
      case ProjectActionsEnum.Edit:
        this.clickHandler(event);
        break;
      case ProjectActionsEnum.Duplicate:
        this.duplicateProject(event.dataItem);
        break;
      case ProjectActionsEnum.Amend:
        this.amendProject(event.dataItem);
        break;
    }
  }


  clickHandler($event: { dataItem: ProjectModel }): void {
    this.router.navigate(['../edit', $event.dataItem.id], { relativeTo: this.activatedRoute });
  }

  deleteCard(project: ProjectModel): void {
    this.confirmationService.openConfirmDialog('Do you want to remove ' + `<b class="text-break">${project.name}</b>` + '?')
      .pipe(
        take(1),
        filter((isConfirmed) => isConfirmed),
      )
      .subscribe(() => {
        this.store.dispatch(deleteProject({ id: project.id }));
      });
  }

  duplicateProject(project: ProjectModel): void {
    this.confirmationService.openConfirmDialog('Do you want to duplicate ' + `<b>${project.name}</b>` + '?')
      .pipe(
        filter((isConfirmed) => isConfirmed),
        switchMap(() => this.projectService.duplicateProject(project.id)),
        tap(() => {
          this.store.dispatch(messagePopup({ message: 'Study duplicated successfully' }));
          this.store.dispatch(loadProjectList());
        }),
        switchMap((duplicatedProject: ProjectModel) => {
          const confirmMessage = `Do you want to continue editing this study?`;
          return this.confirmationService.openConfirmDialog(confirmMessage)
            .pipe(
              filter((isConfirmed) => isConfirmed),
              map(() => duplicatedProject),
            );
        }),
        take(1),
      )
      .subscribe({
        next: (duplicatedProject) => {
          this.router.navigate(['../edit', duplicatedProject.id], { relativeTo: this.activatedRoute });
        },
        error: () => {
          this.store.dispatch(errorPopup({ error: 'There is a problem with duplication' }));
        }
      });
  }

  amendProject(project: ProjectModel): void {
    const confirmMessage = 'Do you want to amend ' + project.name + '?';

    this.confirmationService.openConfirmDialog(confirmMessage)
      .pipe(
        take(1),
        filter((isConfirmed) => !!isConfirmed),
        switchMap(() => {
          return this.projectService.amendProject(project.id);
        }),
        tap(() => { this.store.dispatch(loadProjectList()); }),
        switchMap((project) => {
          return this.confirmationService.openConfirmDialog('Do you want to continue editing this study?')
            .pipe(
              take(1),
              filter((isConfirmed) => isConfirmed),
              map(() => project),
            );
        }),
      )
      .subscribe((project) => {
        this.router.navigate(['../edit', project.id], { relativeTo: this.activatedRoute });
      });
  }

  columnsSelectionChange(columns: ListViewColumnModel[]): void {
    this.config.columns = columns;
    this.setupFilterableFields(columns);
    this.handleFilterUpdate({ action: 'delete', filter: this.lisFilterManager.filters });
  }

  private setupFilterableFields(columns: ListViewColumnModel[]): void {
    this.filterableFields = [
      ...columns.filter(c => c.filter && c.show).map((col: ListViewColumnModel) => (col.filter)),
      {
        field: 'userIdUpdated',
        title: 'Updated by',
        label: 'Updated by',
        type: FilterType.AutoCompleter,
        options: {
          multiple: true,
          autocomplete: true,
          data: this.mappedUsers$
        }
      },
      {
        field: 'userIdCreated',
        title: 'Created by',
        label: 'Created by',
        type: FilterType.AutoCompleter,
        options: {
          autocomplete: true,
          multiple: true,
          data: this.mappedUsers$
        }
      },
    ];
  }

  protected readonly ListCardView = ListCardView;
  protected readonly ProjectStatusEnum = ProjectStatusEnum;
}
