<form [formGroup]="form">
  <div class="d-flex flex-column mb-2">
    <mat-label>Event Name</mat-label>
    <mat-form-field>
      <input
        matInput
        formControlName="eventName"
        placeholder="Enter Event name"
        [maxlength]="50"
        [pharMaxLengthTooltip]="50"
        pharInputTrim>
      <mat-error>
        @if (form.controls.eventName.hasError('required')) {
          Event name is <strong>required</strong>
        } @else if (form.controls.eventName.hasError('notUnique')) {
          Event name must be <b>unique</b>
        }
      </mat-error>
    </mat-form-field>
  </div>
  <mat-label>Event type</mat-label>
  <mat-form-field>
    <mat-select formControlName="elementType">
      @for (eventType of eventTypes; track $index) {
        <mat-option
          [disabled]="eventType.disabled"
          [value]="eventType.value">
          <span>{{ eventType.label }}</span>
        </mat-option>
      }

    </mat-select>
    <mat-error>
      Event type is <strong>required</strong>
    </mat-error>
  </mat-form-field>


</form>
