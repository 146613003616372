/* src/app/assign/assign-list/assign-list.component.scss */
#delete-btn mat-icon {
  width: 19px;
}
#assign-list {
  margin-bottom: 0 !important;
}
#assign-list-container {
  overflow-y: auto;
  height: calc(100vh - var(--main-header-height) - var(--secondary-header-height) - 46px);
}
.expand-collapse-trigger {
  width: 24px;
  height: 24px;
}
/*# sourceMappingURL=assign-list.component.css.map */
