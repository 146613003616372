<!-- <mat-toolbar class="page-toolbar">
  <div class="mfm-second-header_left">
    <button *ngIf="hideSecondLevelMenu" mat-icon-button class="" [routerLink]="[currentRoute]">
      <mat-icon>keyboard_arrow_left</mat-icon> Back to study
    </button>

    <mfm-context-header></mfm-context-header>
  </div>
  <div class="mfm-second-header_center">
    <mfm-third-level-menu></mfm-third-level-menu>
  </div>
  <div class="mfm-second-header_right"></div>
</mat-toolbar> -->
@if (navigateBackLinkInfo$ | async; as navigateBackLinkInfo) {
  <a class="phar-back-link" [routerLink]="navigateBackLinkInfo.routerLink">
    <mat-icon svgIcon="arrow-left"></mat-icon>
    {{ navigateBackLinkInfo.title }}
  </a>
}
<div class="d-flex flex-column">
  @if (!hideSecondLevelMenu) {
    <phar-second-level-menu [links]="secondLevelLinks">
      @if (project | async; as project) {
        <div class="me-3 h-100 d-flex align-items-center">
          <phar-status-badge
            class="form-status-badge"
            [type]="EntityType.Project"
            [status]="project.projectStatus || ProjectStatusEnum.Draft"/>

          @if (project.projectStatus === ProjectStatusEnum.Released ||
          project.projectStatus === ProjectStatusEnum.Rejected ||
          project.projectStatus === ProjectStatusEnum.Amended) {
            <button class="ms-auto list-card_more" mat-icon-button
                    [matMenuTriggerFor]="actionsMenu"
                    (click)="$event.stopImmediatePropagation()">
              <mat-icon class="more">more_vert</mat-icon>
            </button>
            <mat-menu #actionsMenu="matMenu" [overlapTrigger]="false">
              @if (project.projectStatus === ProjectStatusEnum.Rejected || project.projectStatus === ProjectStatusEnum.Amended) {
                <button class="button-with-icon"
                        mat-menu-item
                        (click)="backToDraftAndUnlock(project)"
                >
                  <mat-icon svgIcon="edit"></mat-icon>
                  <span>Edit</span>
                </button>
              }
              @if (project.projectStatus === ProjectStatusEnum.Released) {
                <button
                  class="button-with-icon"
                  mat-menu-item
                  (click)="amendStudy(project)"
                >
                  <mat-icon svgIcon="change"></mat-icon>
                  <span>Amend</span>
                </button>
              }
            </mat-menu>
          }

        </div>

      }
    </phar-second-level-menu>
  } @else {
    <phar-third-level-menu [bottomBordered]="true"/>

  }
  <main [class.scroll-holder--no-header]="!!!(header | async)" class="context-mainContent scroll-holder">
    <router-outlet></router-outlet>
  </main>
</div>
