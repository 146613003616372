import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { AppState } from '../../store/models/app.state';
import { slideInOut } from '../../animations';
import { setContextTitle } from '../../store/actions/ui.actions';
import { ProjectModel } from '../project.model';
import { resetCurrentProject } from '../store/project.actions';
import { NgxPermissionsService } from 'ngx-permissions';
import { NavigateBackLinkInfo, ProjectRouteData } from '../../core/models/route-data.interface';
import { map, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../shared/base.class';

@Component({
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.scss'],
  animations: [slideInOut]
})
export class NewProjectComponent extends BaseComponent implements OnInit {

  navigateBackLinkInfo$: Observable<NavigateBackLinkInfo>;
  sidebar: Observable<string>;
  project: Observable<ProjectModel>;
  secondLevelLinks = [];


  constructor(
    private activatedRoute: ActivatedRoute,
    private permissionsService: NgxPermissionsService,
    private store: Store<AppState>
  ) {
    super();
  }

  ngOnInit(): void {
    this.sidebar = this.store.select(s => s.ui.sidebar);
    this.project = this.store.select(state => state.project.current.project);
    this.store.dispatch(resetCurrentProject());

    this.store.dispatch(setContextTitle({ title: 'New study', position: 1 }));

    this.navigateBackLinkInfo$ = this.activatedRoute.data
      .pipe(map(({ navigateBackLink }: ProjectRouteData) => navigateBackLink));


    // this.permissionsService.permissions$.pipe(
    //   takeUntil(this.destroy$)
    // ).subscribe((permissions) => {
    this.secondLevelLinks = [{
      text: 'Properties',
      url: 'details',
    }];


    // if (permissions.AssignmentRead) {
    this.secondLevelLinks.push({
      text: 'Admin Team',
      url: 'admins',
      isDisabled: true
    });
    this.secondLevelLinks.push({
      text: 'Sites and Study Team',
      url: 'sites-study-team',
      isDisabled: true
    });

    this.secondLevelLinks.push({ text: 'Assessments', url: 'assessments', isDisabled: true });
    // }

    // if (permissions.AssignmentEdit) {
    this.secondLevelLinks.push({ text: 'Events', url: 'events', isDisabled: true });
    this.secondLevelLinks.push({ text: 'Schedule of Assessments', url: 'schedule-assessments', isDisabled: true });

    // }

      this.secondLevelLinks.push({ text: 'Approvals', url: 'study-approvals', isDisabled: true });
      this.secondLevelLinks.push({ text: 'Export', url: 'export-study', isDisabled: true });

    // if (permissions.RecordRead) {
    //   this.secondLevelLinks.push({ text: 'Data', iconClass: 'icon-project_records', url: 'data' });
    // }
    // });
  }

}
