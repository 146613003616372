import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EventModel } from '../../event.model';
import { BaseComponent } from '../../../shared/base.class';
import { IEventNotificationModel } from '../event-notification.model';
import { EventNotificationsService } from '../event-notifications.service';
import { take, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/models/app.state';
import { selectListEventNotificationsState } from '../../store/event.state';
import { loadEventNotificationsListByEventId } from '../../store/event.actions';
import { PharConfirmDialogService } from '../../../shared/confirm-dialog/confirm-dialog-service.service';
import { UserRoleEnum } from '../../../user/user-roles.enum';

@Component({
  selector: 'phar-event-notifications-list',
  templateUrl: 'event-notifications-list.component.html',
  styleUrls: ['event-notifications-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventNotificationsListComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input({ required: true }) event: EventModel;
  @Input() disabled: boolean = false;
  notifications$: Observable<IEventNotificationModel[]>;
  newNotification: IEventNotificationModel | null = null;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly eventNotificationsService: EventNotificationsService,
    private readonly store: Store<AppState>,
    private readonly confirmationDialog: PharConfirmDialogService,
  ) {
    super();
  }

  ngOnInit() {
    this.notifications$ = this.store.select(selectListEventNotificationsState);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  addNotification(): void {
    if (!this.event?.id) {
      return;
    }

    if (this.newNotification) {
      this.confirmationDialog.openConfirmDialog(
        '',
        'Please fill all required fields of the Notification before you proceed',
        'Got it',
        ''
      )
        .pipe(takeUntil(this.destroy$))
        .subscribe();

      return;
    }

    this.newNotification = {
      eventId: this.event.id,
      timing: null,
      number: null,
      unit: null,
      customMessage: '',
      specificTime: '',
      shouldNotifyResponsePerson: true,
      roleTypes: [UserRoleEnum.Participant],
    };
  }

  createNewNotification(notification: IEventNotificationModel): void {
    this.eventNotificationsService.create(notification)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.newNotification = null;
          this.store.dispatch(loadEventNotificationsListByEventId({ id: this.event.id }));
        }
      });
  }
}
