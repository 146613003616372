/* src/app/shared/id-builder/id-builder.component.scss */
#participant-title {
  margin-bottom: 5px;
}
.builder-row {
  column-gap: 10px;
  flex-wrap: wrap;
}
.builder-row .builder-row-item {
  max-width: 150px;
}
/*# sourceMappingURL=id-builder.component.css.map */
