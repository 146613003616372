import { Component, OnDestroy, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';

import { AppState, getState } from '../../store/models/app.state';
import { slideInOut } from '../../animations';
import { globalLoading, removeContextTitle } from '../../store/actions/ui.actions';
import {
  dispatchedCreateProject,
  pendingCreateProject,
  resetCurrentProject,
  searchProjectById,
  selectCurrentProjectManageProject,
  selectCurrentProjectManageProjectSnapshot,
  updateProject
} from '../store/project.actions';
import { ProjectModel, ProjectStatusEnum } from '../project.model';
import { HeaderService } from '../../layout/phar-header/header.service';
import { NavigateBackLinkInfo, ProjectRouteData } from '../../core/models/route-data.interface';
import { EntityType } from '../../core/models/entity-type-enum';
import { FormStatusEnum } from '../../form/form.model';
import { PharConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog-service.service';
import { UtilsService } from '../../core/utils.service';
import { BaseComponent } from '../../shared/base.class';
import { ProjectService } from '../project.service';

@Component({
  templateUrl: './project-context.component.html',
  styleUrls: ['./project-context.component.scss'],
  animations: [slideInOut]
})
export class ProjectContextComponent extends BaseComponent implements OnInit, OnDestroy {
  currentRoute: string;
  header: Observable<boolean>;
  hideSecondLevelMenu: boolean;
  navigateBackLinkInfo$: Observable<NavigateBackLinkInfo>;
  project: Observable<ProjectModel>;
  projectSnapshot$: Observable<ProjectModel>;
  projectId: number;
  secondLevelLinks = [
    {
      text: 'Properties',
      url: 'details'
    },
    {
      text: 'Admin team',
      url: 'admins',
    },
    {
      text: 'Sites and Study Team',
      url: 'sites-study-team',
    },

  ];
  sidebar: Observable<string>;
  protected readonly EntityType = EntityType;
  protected readonly FormStatusEnum = FormStatusEnum;
  protected readonly ProjectStatusEnum = ProjectStatusEnum;
  private readonly routesPerPermissions = {
    read: [
      {
        text: 'Assessments',
        url: 'assessments'
      }
    ],
    edit: [
      {
        text: 'Events',
        url: 'events',
      },
      {
        text: 'Schedule of Assessments',
        url: 'schedule-assessments',
      },
    ],
    noPermissions: [
      { text: 'Approvals', url: 'approvals' },
      { text: 'Export', url: 'export-study' }
    ]
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private headerService: HeaderService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>,
    private permissionsService: NgxPermissionsService,
    private confirmationService: PharConfirmDialogService,
    private utilsService: UtilsService,
    private projectService: ProjectService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.store.dispatch(removeContextTitle({ position: 1 }));
    this.navigateBackLinkInfo$ = this.activatedRoute.data
      .pipe(
        map(({ navigateBackLink }: ProjectRouteData) => navigateBackLink),
      );

    this.router.events.pipe(
      takeUntil(this.destroy$),
    ).subscribe((event: any) => {
      if (event.url) {
        const url: string = event.url;

        if (!this.currentRoute) {
          this.currentRoute = url;
        }
      }

    });

    this.loadSecondLevelLinks();

    this.store.dispatch(resetCurrentProject());
    this.projectId = parseInt(this.route.snapshot.paramMap.get('projectId'), 10);
    this.header = this.store.select(state => state.ui.header);
    this.sidebar = this.store.select(s => s.ui.sidebar);
    this.project = this.store.select(selectCurrentProjectManageProject);
    this.projectSnapshot$ = this.store.select(selectCurrentProjectManageProjectSnapshot);

    if (this.projectId) {
      this.store.dispatch(globalLoading(true));
      this.store.dispatch(searchProjectById({ id: this.projectId }));

      this.projectSnapshot$
        .pipe(
          map((project => project.name)),
          filter((projectName) => !!projectName),
          takeUntil(this.destroy$),
        )
        .subscribe((projectName) => {
          this.headerService.setHeaderText(projectName);
        });
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.store.dispatch(resetCurrentProject());
  }

  loadSecondLevelLinks(): void {
    // if (this.permissionsService.getPermission('AssignmentRead')) {
    this.secondLevelLinks = [...this.secondLevelLinks, ...this.routesPerPermissions.read]
    // }
    // if (this.permissionsService.getPermission('AssignmentEdit')) {
    this.secondLevelLinks = [...this.secondLevelLinks, ...this.routesPerPermissions.edit]

    // }
    this.secondLevelLinks = [...this.secondLevelLinks, ...this.routesPerPermissions.noPermissions]
  }

  backToDraftAndUnlock(project: ProjectModel): void {
    const proposedNewVersion = this.utilsService.incrementVersion(project.projectVersion, 'minor');

    this.confirmationService.openConfirmDialog(
      `Do you want to update the version number to ${proposedNewVersion}?`,
      `${project.name} V${project.projectVersion ?? '0.0.1'}`,
      'Yes',
      'No',
    ).pipe(
      take(1),
      filter((confirm: boolean) => confirm),
    ).subscribe(() => {
      this.updateProject({
        ...getState(this.store).project.current.project,
        projectStatus: ProjectStatusEnum.Draft,
        projectVersion: proposedNewVersion
      })
    })
  }

  amendStudy(project: ProjectModel): void {
    const confirmMessage = 'Do you want to amend ' + project.name + '?';

    this.confirmationService.openConfirmDialog(confirmMessage)
      .pipe(
        take(1),
        filter((isConfirmed) => !!isConfirmed),
        switchMap(() => {
          return this.projectService.amendProject(project.id);
        }),
        switchMap((project) => {
          return this.confirmationService.openConfirmDialog('Do you want to continue editing this study?')
            .pipe(
              take(1),
              filter((isConfirmed) => isConfirmed),
              map(() => project),
            );
        }),
      )
      .subscribe((project) => {
        this.router.navigate(['../', project.id], { relativeTo: this.activatedRoute });
      });
  }

  private updateProject(project: ProjectModel) {
    this.utilsService.dispatchActions(this.store, [
      pendingCreateProject({ pending: true }),
      dispatchedCreateProject({ dispatched: true }),
      updateProject({ project })
    ]);
  }
}

