<div [class.scroll-holder--no-header]="!!!(header$ | async)"
     class="list-page-cards scroll-holder list-page-cards--no-header scroll-holder--inSubContext mb-3"
     id="assign-list">
  <div class="d-flex">
    @if ((isLocked$ | async) === false) {
      <phar-study-assessments
        [listName]="assessmentsContainer"
        [connectedTo]="draggableListViewComponent?.dls || []"
        (assign)="assignForm($event)" />

    }
    <div class="px-3 flex-grow mt-2" id="assign-list-container">
      @if ((isLocked$ | async) === false) {
        <div class="d-flex justify-content-end">
          <button (click)="addGroup()" class="ms-auto" mat-stroked-button>
            <mat-icon svgIcon="plus"></mat-icon>
            Add Group
          </button>
        </div>
      }

      <div class="mb-2 mt-3 w-100 d-flex justify-content-between">
        <phar-list-filter
          [searchField]="'formName'"
          [activeFilters]="lisFilterManager.filters$ | async"
          [displayedFilterOptions]="filterableFields"
          (filterAdd)="handleFilterChange($event)"
          (filterUpdate)="handleFilterUpdate($event)"
        />
      </div>

      @if ((listView$ | async); as listView) {
        <phar-draggable-list-view
          #draggableListViewComponent
          [connectedListId]="assessmentsContainer"
          [idClassMap]="idClassMap()"
          [groupClass]="'assign-group-background'"
          [config]="config"
          [dataSource]="listView"
          [groupFieldValue]="AssignType.AssignGroupEntity"
          [groupField]="'assignmentType'"
          [draggingDisabled]="isLocked$ | async"
          [orderField]="'orderPriority'"
          [initiallyExpandedItems]="initiallyExpandedItems()"
          (elementAdded)="handleElementAdded($event)"
          (afterActionClicked)="actionHandler($event)"
          (afterReorder)="onReorder($event)"/>
      }
    </div>

    @if ((groupEditorOpened$ | async) && (isLocked$ | async) === false) {
      <div>
        <phar-assign-group-editor
          (discard)="closeGroupEditor()"
          (save)="saveGroup($event)"
          [assignment]="selectedAssignment"/>
      </div>
    }
  </div>
</div>
<ng-template #createdAtTemplate let-element>
  {{ element.createdAt | date: 'd MMM y' }}
</ng-template>

<ng-template #dragIconTemplate let-element>
  @if ((isLocked$ | async) === true) {
    <button cdkDragHandle class="cursor-move" draggable="true" mat-icon-button [disableRipple]="true">
      <mat-icon class="button-block_small-icon" svgIcon="handle"></mat-icon>
    </button>
  }
</ng-template>

<ng-template #expandIconTemplate let-element>
  @if (element.type === FieldType.Group) {
    <button class="expand-collapse-trigger d-flex justify-content-center ms-2 p-0" mat-icon-button
            [disableRipple]="true">
      @if (element.isExpanded) {
        <mat-icon class="button-block_small-icon small-icon" svgIcon="arrow-down"></mat-icon>
      } @else {
        <mat-icon class="button-block_small-icon small-icon" svgIcon="arrow-right"></mat-icon>
      }
    </button>
  }
</ng-template>

<ng-template #formNameTemplate let-element>
  @if (element.type === FieldType.Group) {
    {{ element.groupName }}
  } @else {
    {{ element.formName }}

  }
</ng-template>

<ng-template #actionsTemplate let-element>
  @if ((isLocked$ | async) === false) {
    <button
      class="ms-auto list-card_more me-2"
      mat-icon-button
      [matMenuTriggerFor]="actionsMenu"
      [matMenuTriggerData]="{$implicit: element.type === FieldType.Group ? rowGroupActions : rowAssessmentActions}"
      (click)="$event.stopImmediatePropagation()">
      <mat-icon class="more">more_vert</mat-icon>
    </button>
    <mat-menu #actionsMenu="matMenu" [overlapTrigger]="false">
      <ng-template matMenuContent let-actions>
        @for (action of actions; track $index) {
          <button
            class="button-with-icon me-2"
            mat-menu-item
            [disabled]="action.disabled || (action.eventName === AssignItemActions.Preview && !element.formId)"
            (click)="actionHandler({eventName: action.eventName, dataItem: element })">
            @if (action.icon) {
              <mat-icon [svgIcon]="action.icon"></mat-icon>
            }
            <span>{{ action.title }}</span>

            @if (action.eventName === AssignItemActions.Comments) {
              ({{ commentsCounter()[element.id] || 0 }})
            }
          </button>
        }
      </ng-template>
    </mat-menu>
  }
</ng-template>

<ng-template #assignmentTypeTemplate let-element>
  <div class="d-flex align-items-center">
    @switch (element.assignmentType) {
      @case (AssignType.AssignGroupEntity) {
        <mat-icon class="form-template_left-block_form-icon me-2" svgIcon="folder"></mat-icon>
        <span>Group</span>
      }
      @case (AssignType.Questionnaire) {
        <mat-icon class="form-template_left-block_form-icon me-2" svgIcon="forms"></mat-icon>
        <span>eClinRO</span>
      }
      @case (AssignType.EObsRO) {
        <mat-icon class="form-template_left-block_form-icon me-2" svgIcon="forms"></mat-icon>
        <span>eObsRo</span>
      }
      @case (AssignType.DataCapture) {
        <mat-icon class="form-template_left-block_form-icon me-2" svgIcon="forms"></mat-icon>
        <span>ePRO</span>
      }
      @case (AssignType.InformedConsent) {
        <mat-icon class="form-template_left-block_form-icon me-2" svgIcon="forms"></mat-icon>
        <span>Informed Consent</span>
      }
    }
  </div>
</ng-template>
