import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DateTimeUnitEnum } from "../enums/datetime-unit.enum";
import { ElementTypeEnum } from "../enums/element-type.enum";
import { Store } from "@ngrx/store";
import { AppState, getState } from "../../store/models/app.state";
import { combineLatest, EMPTY, Observable, Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from "rxjs/operators";
import { INPUT_DEBOUNCE_TIME } from "../../core/config/app.constants";
import { CurrentEventManage, selectListProjectEventsState } from "../store/event.state";
import { EventModel } from "../event.model";
import { isEqual } from "lodash-es";
import {
  createProjectEvent,
  dispatchedCreateUpdateEvent,
  pendingCreateUpdateEvent,
  revertEventEditorChanges,
  successCreateUpdateEvent,
  updateEditor,
  updateEventField,
  updateProjectEvent
} from "../store/event.actions";
import { DropdownWithIconModel } from "../../shared/models/dropdown-with-icon-model";
import { DropdownModel } from "../../shared/models/dropdown-model";
import { ProjectEventModel } from "../project-event.model";
import { Actions, ofType } from "@ngrx/effects";
import { UtilsService } from '../../core/utils.service';
import { uniqueEventNameValidator } from '../unique-event-name.validator';
import { ExtractFormControl } from '../../shared/types/extract-form-controls.type';
import { EventAvailabilityTypeEnum } from '../enums/event-availability-type.enum';

type EditableEventDetails = Pick<EventModel, 'eventName' | 'elementType' | 'eventAvailability' | 'start' | 'unit' |
  'specificTime' | 'beforeDeviation' | 'beforeDeviationUnit' |
  'afterDeviation' | 'afterDeviationUnit' | 'isRepeatable' | 'repeatEvery' | 'repeatTitles' |
  'repeatEveryUnit' | 'endsAfter' | 'endsAfterUnit' | 'hasSpecificTime'>;

type EventFormGroup = ExtractFormControl<EditableEventDetails>;

@Component({
  selector: "phar-scheduled-event-editor",
  templateUrl: "./scheduled-event-editor.component.html",
  styleUrls: ["./scheduled-event-editor.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduledEventEditorComponent implements OnInit, OnDestroy {
  @Input() opened: boolean;
  @Input() width: number;
  @Output() public afterUpdate: EventEmitter<{ id: number, update: boolean }> = new EventEmitter<{
    id: number,
    update: boolean
  }>();
  form: FormGroup<EventFormGroup>;
  form$: Observable<FormGroup<EventFormGroup>>;
  projectId: number;
  elementTypes = [ElementTypeEnum.ScheduledEvent, ElementTypeEnum.Group];
  timeTypes = [DateTimeUnitEnum.Hour, DateTimeUnitEnum.Day, DateTimeUnitEnum.Week, DateTimeUnitEnum.Month, DateTimeUnitEnum.Year];
  isNewEvent = true;
  hasSpecificTimeSeconds = false;
  hasSpecificTimePeriod = false;
  eventState$: Observable<CurrentEventManage>;
  currentEvent$: Observable<EventModel>;
  currentEventSnapshot$: Observable<EventModel>;
  hasPendingChanges$: Observable<boolean>;
  selectedElementType: DropdownWithIconModel;
  readonly elementTypeDisplayOptions: DropdownWithIconModel[] = [
    {
      label: 'Scheduled Event',
      value: ElementTypeEnum.ScheduledEvent,
      icon: 'calendar'
    },
    {
      label: 'Group',
      value: ElementTypeEnum.Group,
      icon: 'folder',
      disabled: true
    }
  ];
  readonly timeUnitDisplayOptions: DropdownModel[] = [
    {
      label: 'Hour(s)',
      value: DateTimeUnitEnum.Hour,
    },
    {
      label: 'Day(s)',
      value: DateTimeUnitEnum.Day,
    },
    {
      label: 'Week(s)',
      value: DateTimeUnitEnum.Week,
    },
    {
      label: 'Month(s)',
      value: DateTimeUnitEnum.Month,
    },
    {
      label: 'Year(s)',
      value: DateTimeUnitEnum.Year,
    }
  ];
  readonly eventAvailabilityDisplayOptions: DropdownModel[] = [
    {
      label: 'During Screening',
      value: EventAvailabilityTypeEnum.DuringScreening,
    },
    {
      label: 'During Enrollment',
      value: EventAvailabilityTypeEnum.DuringEnrollment,
    },
    {
      label: 'Throughout Study',
      value: EventAvailabilityTypeEnum.ThroughoutStudy,
    },
  ];
  readonly EventAvailabilityTypeEnum = EventAvailabilityTypeEnum;

  get repeatTitlesFormArray(): FormArray {
    return (this.form?.controls?.repeatTitles as unknown) as FormArray
  }

  private _disabled: boolean = false;
  private readonly destroy$ = new Subject<null>();

  constructor(
    private actions$: Actions,
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private utilsService: UtilsService,
  ) {
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(isDisabled: boolean) {
    this._disabled = isDisabled;

    if (!this.form) {
      return;
    }

    if (this.disabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }


  ngOnInit(): void {
    this.selectedElementType = this.elementTypeDisplayOptions.find(option => option.value === ElementTypeEnum.ScheduledEvent);

    this.projectId = getState(this.store).project.current.project.id;

    this.eventState$ = this.store.select(state => state.event.current);
    this.currentEvent$ = this.eventState$.pipe(
      map(current => current.projectEvent.event),
      distinctUntilChanged(isEqual),
      map((event) => ({ ...event, specificTime: this.getParsedSpecificTime(event.specificTime) })),
    );
    this.currentEventSnapshot$ = this.eventState$.pipe(
      map(current => current.projectEventSnapshot.event),
      distinctUntilChanged(isEqual),
      map((event) => ({ ...event, specificTime: this.getParsedSpecificTime(event.specificTime) })),
    );
    this.hasPendingChanges$ = combineLatest([this.currentEvent$, this.currentEventSnapshot$])
      .pipe(
        map((events) => events.map((event: EventModel): EditableEventDetails => {
          const {
            eventName,
            elementType,
            eventAvailability,
            start,
            unit,
            specificTime,
            beforeDeviation,
            beforeDeviationUnit,
            afterDeviation,
            afterDeviationUnit,
            isRepeatable,
            repeatEvery,
            repeatEveryUnit,
            repeatTitles,
            endsAfter,
            endsAfterUnit,
            hasSpecificTime,
          } = event;
          return {
            eventName,
            elementType,
            eventAvailability,
            start,
            unit,
            specificTime,
            beforeDeviation,
            beforeDeviationUnit,
            afterDeviation,
            afterDeviationUnit,
            isRepeatable,
            repeatEvery,
            repeatTitles,
            repeatEveryUnit,
            endsAfter,
            endsAfterUnit,
            hasSpecificTime,
          };
        })),
        map(([editableEventDetails, editableEventDetailsSnapshot]) => {
          return !isEqual(editableEventDetails, editableEventDetailsSnapshot);
        }),
        distinctUntilChanged(),
      );

    this.store.dispatch(successCreateUpdateEvent({ success: false }));

    // when the add button(for events) is clicked this code is triggered and the form is return to
    // initiial state (the state is reset before the action is called)
    this.form$ = this.actions$.pipe(
      ofType<ReturnType<typeof updateEditor>>(updateEditor),
      startWith(EMPTY),
      map(() => {
        const event = { ...getState(this.store).event.current.projectEvent.event };
        this.isNewEvent = !event.id;
        if (!event.elementType) {
          event.elementType = ElementTypeEnum.ScheduledEvent;
        }

        return this.getFormGroup(event);
      }),
      tap((form) => {
        if (this.disabled) {
          form.disable();
        }
      }),

      takeUntil(this.destroy$),
      shareReplay(1),
    );

    this.form$
      .pipe(takeUntil(this.destroy$))
      .subscribe((formGroup) => {
        this.form = formGroup;
        if (this.form.get('eventAvailability').value === EventAvailabilityTypeEnum.DuringEnrollment) {
          this.handleIsRepeating(formGroup.value.isRepeatable);
          this.handleSpecificTimeValidation(formGroup.value.hasSpecificTime);
          this.initListeners();
        }
      });

    this.updateStateOnValueChange(this.form$);

  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  addEditEventSettings(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const projectEvent: ProjectEventModel = {
      ...getState(this.store).event.current.projectEvent
    };

    this.store.dispatch(pendingCreateUpdateEvent({ pending: true }));
    this.store.dispatch(dispatchedCreateUpdateEvent({ dispatched: true }));

    if (projectEvent.id == null) {
      projectEvent.projectId = this.projectId;
      this.store.dispatch(createProjectEvent({ projectEvent }));
    } else {
      this.store.dispatch(updateProjectEvent({ projectEvent }));
    }

    this.eventState$.pipe(
      filter(({ dispatched, success }) => dispatched && success),
      tap((state: CurrentEventManage) => {
        this.store.dispatch(pendingCreateUpdateEvent({ pending: false }));
        this.store.dispatch(successCreateUpdateEvent({ success: false }));
        this.afterUpdate.emit({ id: state.projectEvent.event.id, update: !this.isNewEvent });
      }),
      switchMap(() => this.hasPendingChanges$),
      take(1),
    ).subscribe();
  }

  discardChanges(): void {
    // revert the changes in the editor to to last saved
    this.store.dispatch(revertEventEditorChanges());
    this.store.dispatch(updateEditor());
  }

  toggleFields(controlName): void {
    this.form.get(controlName).setValue(!this.form.get(controlName).value);
  }

  private getFormGroup(event: EventModel): FormGroup<EventFormGroup> {
    const specificTime = this.getParsedSpecificTime(event.specificTime);
    let repeatTitlesData = [];
    if (event.repeatTitles && event.repeatTitles.length) {
      repeatTitlesData = event.repeatTitles.split(',').map((message) => this.formBuilder.control(message));
    }

    const isDuringEnrollment = event.eventAvailability === EventAvailabilityTypeEnum.DuringEnrollment;
    const duringEnrollmentEventFormControls = {
      elementType: [event.elementType],
      start: [event.start, Validators.required],
      unit: [event.unit],
      hasSpecificTime: [Boolean(event.specificTime)],
      specificTime: [specificTime, Boolean(event.specificTime) ? Validators.required : null],
      beforeDeviation: [event.beforeDeviation],
      beforeDeviationUnit: [event.beforeDeviationUnit],
      afterDeviation: [event.afterDeviation],
      afterDeviationUnit: [event.afterDeviationUnit],
      isRepeatable: [event.isRepeatable],
      repeatEvery: [event.repeatEvery],
      repeatTitles: new FormArray(repeatTitlesData),
      repeatEveryUnit: [event.repeatEveryUnit],
      endsAfter: [event.endsAfter],
      endsAfterUnit: [event.endsAfterUnit],
    };

    // @ts-ignore
    return this.formBuilder.group({
      eventName: [event.eventName, [Validators.required], [uniqueEventNameValidator(this.store.select(selectListProjectEventsState), event)]],
      eventAvailability: [{ value: event.eventAvailability, disabled: !this.isNewEvent }, [Validators.required]],
      ...(isDuringEnrollment && duringEnrollmentEventFormControls),
    });

  }

  private getFormControlValueChanges<K extends keyof EventFormGroup>(
    form$: Observable<FormGroup<EventFormGroup>>,
    formControlKey: K,
  ): Observable<EditableEventDetails[K]> {
    return form$.pipe(
      map((form) => form.get(formControlKey)),
      filter((formControl) => !!formControl),
      switchMap((formControl) => formControl.valueChanges),
    );
  }

  private updateStateOnValueChange(form$: Observable<FormGroup<EventFormGroup>>): void {
    this.getFormControlValueChanges(form$, 'eventName').pipe(
      debounceTime(INPUT_DEBOUNCE_TIME),
      takeUntil(this.destroy$),
    )
      .subscribe(eventName => {
        this.store.dispatch(updateEventField({ field: 'eventName', value: eventName }));
      });

    this.getFormControlValueChanges(form$, 'elementType').pipe(
      startWith(ElementTypeEnum.ScheduledEvent),
      filter((elementType) => !!elementType),
      takeUntil(this.destroy$),
    )
      .subscribe(elementType => {
        this.store.dispatch(updateEventField({ field: 'elementType', value: elementType }));
        this.selectedElementType = this.elementTypeDisplayOptions.find(option => option.value === elementType);
      });

    this.getFormControlValueChanges(form$, 'eventAvailability')
      .pipe(takeUntil(this.destroy$))
      .subscribe((eventAvailability) => {
        if (this.isNewEvent && eventAvailability !== EventAvailabilityTypeEnum.DuringEnrollment) {
          const eventName = this.form.get('eventName').value;

          this.store.dispatch(revertEventEditorChanges());
          this.store.dispatch(updateEventField({ field: 'eventName', value: eventName }));
          this.store.dispatch(updateEventField({ field: 'start', value: 0 }));
          this.store.dispatch(updateEventField({ field: 'eventAvailability', value: eventAvailability }));
          this.store.dispatch(updateEditor());
        } else {
          this.store.dispatch(updateEventField({ field: 'eventAvailability', value: eventAvailability }));
          this.store.dispatch(updateEditor());

        }
      });

    this.getFormControlValueChanges(form$, 'start').pipe(
      debounceTime(INPUT_DEBOUNCE_TIME),
      takeUntil(this.destroy$),
    )
      .subscribe(start => {
        this.store.dispatch(updateEventField({ field: 'start', value: Number(start) }));
      });

    this.getFormControlValueChanges(form$, 'unit').pipe(
      takeUntil(this.destroy$),
    )
      .subscribe(unit => {
        this.store.dispatch(updateEventField({ field: 'unit', value: unit }));
      });

    this.getFormControlValueChanges(form$, 'hasSpecificTime').pipe(
      debounceTime(INPUT_DEBOUNCE_TIME),
      takeUntil(this.destroy$),
    )
      .subscribe((hasSpecificTime) => {
        this.store.dispatch(updateEventField({ field: 'hasSpecificTime', value: hasSpecificTime }));
        this.handleSpecificTimeValidation(hasSpecificTime);
      });

    this.getFormControlValueChanges(form$, 'specificTime').pipe(
      debounceTime(INPUT_DEBOUNCE_TIME),
      takeUntil(this.destroy$),
    )
      .subscribe((specificTime: string) => {
        this.store.dispatch(updateEventField({ field: 'specificTime', value: specificTime }));
      });

    this.getFormControlValueChanges(form$, 'beforeDeviation').pipe(
      debounceTime(INPUT_DEBOUNCE_TIME),
      takeUntil(this.destroy$),
    )
      .subscribe(beforeDeviation => {
        this.store.dispatch(updateEventField({ field: 'beforeDeviation', value: beforeDeviation }));
      });

    this.getFormControlValueChanges(form$, 'beforeDeviationUnit').pipe(
      takeUntil(this.destroy$),
    )
      .subscribe(beforeDeviationUnit => {
        this.store.dispatch(updateEventField({ field: 'beforeDeviationUnit', value: beforeDeviationUnit }));
      });

    this.getFormControlValueChanges(form$, 'afterDeviation').pipe(
      debounceTime(INPUT_DEBOUNCE_TIME),
      takeUntil(this.destroy$),
    )
      .subscribe(afterDeviation => {
        this.store.dispatch(updateEventField({ field: 'afterDeviation', value: afterDeviation }));
      });

    this.getFormControlValueChanges(form$, 'afterDeviationUnit').pipe(
      takeUntil(this.destroy$),
    )
      .subscribe(afterDeviationUnit => {
        this.store.dispatch(updateEventField({ field: 'afterDeviationUnit', value: afterDeviationUnit }));
      });

    this.getFormControlValueChanges(form$, 'isRepeatable').pipe(
      debounceTime(INPUT_DEBOUNCE_TIME),
      takeUntil(this.destroy$),
    )
      .subscribe(isRepeatable => {
        this.store.dispatch(updateEventField({ field: 'isRepeatable', value: isRepeatable }));
        this.handleIsRepeating(isRepeatable);
      });

    this.getFormControlValueChanges(form$, 'repeatEvery').pipe(
      debounceTime(INPUT_DEBOUNCE_TIME),
      takeUntil(this.destroy$),
    )
      .subscribe(repeatEvery => {
        this.store.dispatch(updateEventField({ field: 'repeatEvery', value: repeatEvery }));
      });


    this.getFormControlValueChanges(form$, 'repeatTitles').pipe(
      takeUntil(this.destroy$),
    )
      // @ts-ignore
      .subscribe((repeatEveryTitles: string[]) => {
        // in the database this is string but the formArray produces array ot strings so we need to convert it
        this.store.dispatch(updateEventField({ field: 'repeatTitles', value: repeatEveryTitles.join() }));
      });

    this.getFormControlValueChanges(form$, 'repeatEveryUnit').pipe(
      takeUntil(this.destroy$),
    )
      .subscribe(repeatEveryUnit => {
        this.form.get('endsAfterUnit').patchValue(repeatEveryUnit);
        this.store.dispatch(updateEventField({ field: 'repeatEveryUnit', value: repeatEveryUnit }));
      });

    this.getFormControlValueChanges(form$, 'endsAfter').pipe(
      debounceTime(INPUT_DEBOUNCE_TIME),
      takeUntil(this.destroy$),
    )
      .subscribe(endsAfter => {
        this.store.dispatch(updateEventField({ field: 'endsAfter', value: endsAfter }));
      });

    this.getFormControlValueChanges(form$, 'endsAfterUnit').pipe(
      takeUntil(this.destroy$),
    )
      .subscribe(endsAfterUnit => {
        this.store.dispatch(updateEventField({ field: 'endsAfterUnit', value: endsAfterUnit }));
      });
  }

  private getParsedSpecificTime(specificTime: string): string {
    return specificTime?.split('T').pop();
  }

  private handleIsRepeating(isRepeatable: boolean): void {
    const endsAfter = this.form.get('endsAfter');
    const endsAfterUnit = this.form.get('endsAfterUnit');
    const repeatEvery = this.form.get('repeatEvery');
    const repeatEveryUnit = this.form.get('repeatEveryUnit');
    if (isRepeatable) {
      endsAfter.setValidators(Validators.required);
      endsAfterUnit.setValidators(Validators.required);
      repeatEvery.setValidators(Validators.required);
      repeatEveryUnit.setValidators(Validators.required);
    } else {
      endsAfter.clearValidators();
      endsAfterUnit.clearValidators();
      repeatEvery.clearValidators();
      repeatEveryUnit.clearValidators();
      endsAfter.setValue(null);
      repeatEvery.setValue(null);
    }
    endsAfter.updateValueAndValidity();
    endsAfterUnit.updateValueAndValidity();
    repeatEvery.updateValueAndValidity();
    repeatEveryUnit.updateValueAndValidity();
  }

  private handleSpecificTimeValidation(hasSpecificTime: boolean): void {
    const specificTimeFormCtrl = this.form.get('specificTime');
    if (hasSpecificTime) {
      specificTimeFormCtrl.setValidators(Validators.required);
    } else {
      specificTimeFormCtrl.clearValidators();
      specificTimeFormCtrl.setValue(null);
    }
    specificTimeFormCtrl.updateValueAndValidity();
  }

  private initListeners(): void {
    this.form.controls.endsAfter.valueChanges.pipe(
      takeUntil(this.destroy$),
    ).subscribe({
      next: (val) => {
        this.handleRepeatFreqChanged(val)
      }
    })
  }

  private handleRepeatFreqChanged(val: number): void {
    if (val === 0 || val === null || val === undefined) {
      this.repeatTitlesFormArray.clear();
      return;
    }
    const currentLength = this.repeatTitlesFormArray.controls.length;
    if (currentLength < val) {
      for (let i = 0; i < (val - currentLength); i++) {
        this.repeatTitlesFormArray.push(this.formBuilder.control('', Validators.required));
      }
    } else {
      for (let i = 0; i < Math.abs(currentLength - val); i++) {
        this.repeatTitlesFormArray.removeAt(this.repeatTitlesFormArray.controls.length - 1);
      }
    }
  }
}
