import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pharTranslateId',
  standalone: true,
})

export class TranslateIdPipe implements PipeTransform {
  transform(formula: string, entity: any, separator: string, previewSeparator: string): any {
    if (!formula || formula === '') {
      return `${previewSeparator}?${previewSeparator}`;
    }
    const formulaToArr = this.findWordsBetweenChars(formula, separator)
    return formulaToArr.map(formulaItem => {
      if (entity.hasOwnProperty(formulaItem)) {
        return entity[formulaItem];
      } else {
        return formulaItem
      }
    }).join(previewSeparator);
  }

  findWordsBetweenChars(text: string, char: string) {
    const escapedChar = char.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters for regex
    // const regex = new RegExp(`${escapedChar}(\\w+?|\\s+?)${escapedChar}`, 'g');
    const regex = new RegExp(`(?<=${escapedChar})(.*?)(?=${escapedChar})`, 'g')
    return [...text.matchAll(regex)].map(match => match[1]);
  }


}
