<div id="events" class="h-100">
  @if (selectedTab === EventTab.ScheduledEvents) {
    <phar-scheduled-events-list
      #scheduledEventsListComponent
      [isLocked]="isLocked$ | async"
      (duplicateEvent)="duplicateEvent($event)">
      <ng-template [ngTemplateOutlet]="switchEventsListTmp"></ng-template>
    </phar-scheduled-events-list>
  } @else {
    <phar-adhoc-events-list
      #adhocEventsListComponent
      [isLocked]="isLocked$ | async"
      (duplicateEvent)="duplicateEvent($event)">
      <ng-template [ngTemplateOutlet]="switchEventsListTmp"></ng-template>
    </phar-adhoc-events-list>
  }

</div>

<ng-template #switchEventsListTmp>
  <div class="d-flex align-items-center pt-3 px-4">
    <div class="ms-auto">
      <button (click)="setActiveTab(EventTab.ScheduledEvents)"
              [ngClass]="{'active-switch-button': selectedTab === EventTab.ScheduledEvents}"
              class="left-switch-button cursor-pointer"
              mat-button>Scheduled Events
      </button>
      <button (click)="setActiveTab(EventTab.AdhocEvents)"
              [ngClass]="{'active-switch-button': selectedTab === EventTab.AdhocEvents}"
              class="right-switch-button cursor-pointer"
              mat-button>Adhoc Events
      </button>
    </div>
    <div class="ms-auto">
      @if ((isLocked$ | async) === false) {
        <button (click)="add()" class="ms-auto btn-small" mat-raised-button>
          <mat-icon svgIcon="plus"></mat-icon>
          <b>Add</b>
        </button>
      }
    </div>
  </div>

</ng-template>
