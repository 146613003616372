import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ApprovalStatus, ApprovalStatusType } from '../../shared/approvals/approvals.component';
import { AppState } from '../../store/models/app.state';
import { Store } from '@ngrx/store';
import {
  checkCurrentProjectLockedState,
  dispatchedCreateProject,
  pendingCreateProject,
  selectCurrentProjectManageProject,
  updateProject
} from '../store/project.actions';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ProjectModel, ProjectStatusEnum } from '../project.model';
import { UtilsService } from '../../core/utils.service';
import { globalLoading } from '../../store/actions/ui.actions';
import { BaseComponent } from '../../shared/base.class';
import { PharConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog-service.service';

@Component({
  templateUrl: 'study-approvals.component.html',
  styleUrls: ['./study-approvals.component.scss'],
})

export class StudyApprovalsComponent extends BaseComponent implements OnInit, OnDestroy {

  status$: Observable<ApprovalStatusType>;
  project: ProjectModel;
  isLocked$: Observable<boolean>

  constructor(private store: Store<AppState>, private utilsService: UtilsService, private confirmDialog: PharConfirmDialogService) {
    super();
  }

  ngOnInit() {
    this.isLocked$ = this.store.select(checkCurrentProjectLockedState)
    this.status$ = this.store.select(selectCurrentProjectManageProject).pipe(
      map((project: ProjectModel) => {
        this.project = project;
        return project.projectStatus as ApprovalStatusType ?? ApprovalStatus.Draft
      })
    )
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  handleStatusChange(event: { status: ApprovalStatusType }): void {
    if (event.status === ApprovalStatus.Released) {
      let nextVersion = this.utilsService.incrementVersion(this.project.projectVersion ?? '0.0.1', 'major')
      this.confirmDialog.openConfirmDialog(
        `Have all reviewers approved the Study?`,
        `${this.project.name}`,
        'Yes',
        'No',
      ).pipe(
        filter(isConfirmed => !!isConfirmed),
        switchMap(() => this.confirmDialog.openConfirmDialog(
          `Do you want to update the version number to ${nextVersion}?`,
          `${this.project.name} V${this.project.projectVersion ?? '0.0.1'}`,
          'Yes',
          'No',
        )),
        tap((updateVersion) => {
          if (!updateVersion) {
            nextVersion = this.project.projectVersion;
          }
          //call the real update
          this.updateProject({ ...this.project, projectVersion: nextVersion }, event.status as ProjectStatusEnum)

        }),
        switchMap(() => (this.status$)), // change the flow to get wait for status change
        filter((status) => status === event.status), // filter to get the updates status only
        tap((status: ProjectStatusEnum) => {

          this.confirmDialog.openConfirmDialog(
            '',
            `${this.project.name} is now released`,
            'Got it',
            '',
          )
        }),
        takeUntil(this.destroy$)
      ).subscribe();
      return;
    }

    this.updateProject(this.project, event.status as ProjectStatusEnum);
  }

  private getDateField(projectStatus: ApprovalStatusType): { [key: string]: string } | null {
    const date = new Date().toJSON();
    switch (projectStatus) {
      case ProjectStatusEnum.PendingReview:
        return { dateSentForReview: date };
      case ProjectStatusEnum.Rejected:
        return { rejectionDate: date };
      case ProjectStatusEnum.UnderReview:
        return { dateReviewStarted: date };
      case ProjectStatusEnum.Released:
        return { releaseDate: date };
      case ProjectStatusEnum.Amended:
        return { amendedDate: date };
      default:
        return null;
    }

  }

  private updateProject(projectData: ProjectModel, projectStatus: ProjectStatusEnum): void {


    const dateField = this.getDateField(projectStatus);
    let project = {
      ...projectData,
      projectStatus,
    }

    if (dateField) {
      project = {
        ...project,
        ...dateField
      }
    }
    this.utilsService.dispatchActions(this.store, [
      globalLoading(true),
      pendingCreateProject({ pending: true }),
      dispatchedCreateProject({ dispatched: true }),
      updateProject({ project })
    ]);
  }
}
