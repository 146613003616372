import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssignListComponent } from './assign-list/assign-list.component';
import { AssignService } from './assign.service';
import { SharedModule } from '../shared/shared.module';
import { PermissionModule } from '../permission/permission.module';
import { StudyAssessmentsComponent } from './study-assessments/study-assessments.component';
import { AssignGroupEditorComponent } from './assign-group-editor/assign-group-editor.component';
import {PharTooltipDirective} from "../shared/directives/tooltip.directive";
import {ManageFiltersPipe} from "../shared/list-filter/manage-filters.pipe";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        PermissionModule,
        // UserModule,
        PharTooltipDirective,
        ManageFiltersPipe
    ],
    declarations: [AssignListComponent, StudyAssessmentsComponent, AssignGroupEditorComponent],
    exports: [AssignListComponent, StudyAssessmentsComponent],
    providers: [AssignService]
})
export class AssignModule {
}
