<div [ngSwitch]="elementType" class="d-flex align-items-center">
  <ng-container *ngSwitchCase="ElementType.AdverseEvent">
    <mat-icon class="form-template_left-block_form-icon me-2" svgIcon="calendar"></mat-icon>
    <span>Adverse Event</span>
  </ng-container>

  <ng-container *ngSwitchCase="ElementType.SevereAdverseEvent">
    <mat-icon class="form-template_left-block_form-icon me-2" svgIcon="alert"></mat-icon>
    <span>Severe Adverse Event</span>
  </ng-container>
  <ng-container *ngSwitchCase="ElementType.Withdraw">
    <mat-icon class="form-template_left-block_form-icon me-2" svgIcon="flag"></mat-icon>
    <span>Withdraw</span>
  </ng-container>

  <ng-container *ngSwitchCase="ElementType.Baseline">
    <mat-icon class="form-template_left-block_form-icon me-2" svgIcon="play"></mat-icon>
    <span>Baseline</span>
  </ng-container>

  <ng-container *ngSwitchCase="ElementType.ScheduledEvent">
    <mat-icon class="form-template_left-block_form-icon me-2" svgIcon="calendar"></mat-icon>
    <span>Scheduled Event</span>
  </ng-container>

  <ng-container *ngSwitchCase="ElementType.Group">
    <mat-icon class="form-template_left-block_form-icon me-2" svgIcon="folder"></mat-icon>
    <span>Group</span>
  </ng-container>
</div>
