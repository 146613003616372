import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { EventService } from './event.service';
import { PermissionModule } from '../permission/permission.module';
import { ScheduledEventsListComponent } from './scheduled-events-list/scheduled-events-list.component';
import { ScheduledEventEditorComponent } from './scheduled-events-editor/scheduled-event-editor.component';
import { AssignModule } from '../assign/assign.module';
import { AdhocEventEditorComponent } from './adhoc-event-editor/adhoc-event-editor.component';
import {
  AdhocEventEditorFormComponent
} from './adhoc-event-editor/adhoc-event-editor-form/adhoc-event-editor-form.component';
import { EventsComponent } from './events/events.component';
import { AdhocEventsListComponent } from './adhoc-events-list/adhoc-events-list.component';
import { EventTypeComponent } from './event-type/event-type.component';
import { ScrollEndDirective } from '../shared/directives/scroll-end-recognizer.directive';
import {
  EventNotificationComponent,
  EventNotificationsListComponent,
  EventNotificationsService
} from './event-notifications';
import {PharTooltipDirective} from "../shared/directives/tooltip.directive";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        PermissionModule,
        AssignModule,
        EventTypeComponent,
        ScrollEndDirective,
        PharTooltipDirective
    ],
  providers: [ EventService, EventNotificationsService ],
  declarations: [
    ScheduledEventsListComponent,
    ScheduledEventEditorComponent,
    AdhocEventEditorComponent,
    AdhocEventEditorFormComponent,
    EventsComponent,
    AdhocEventsListComponent,
    EventNotificationsListComponent,
    EventNotificationComponent,
  ],
})
export class PharEventModule {
}
