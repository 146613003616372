<phar-list-template #listTemplate>
  <div listHeader>
    <div class="d-flex flex-row justify-content-between mt-2 px-4">
      <button id="add-study" class="ms-auto" (click)="goToCreateNew()" mat-stroked-button>
        <mat-icon svgIcon="plus"></mat-icon>
        Add
      </button>
    </div>
    <div class="d-flex flex-row justify-content-between mt-4 mb-2 ms-3">
      <phar-list-filter [searchField]="'name'"
                        [activeFilters]="lisFilterManager.filters$ | async"
                        [displayedFilterOptions]="filterableFields"
                        [resultCounter]="(listView$ | async).length"
                        (filterAdd)="handleFilterChange($event)"
                        (filterUpdate)="handleFilterUpdate($event)"
      />
      <div class="d-flex flex-row justify-content-between ps-3 pe-2">
        <phar-card-list-switcher [view]="view"
                                 [ngClass]="{'me-3': view !== ListCardView.List}"
                                 (afterViewSelected)="changeView($event)"/>
        <phar-list-column-selection
          class="ms-1"
          [class.d-none]="view !== ListCardView.List"
          [columns]="config.columns"
          (columnsSelectionChange)="columnsSelectionChange($event)"/>
      </div>
    </div>

  </div>

  <div class="list-page-cards" listBody [class.scroll-holder--no-header]="!!!(header | async)">
    @if (view === ListCardView.Card) {
      <phar-cards-list
        [cardsType]="'project'"
        [moreOptions]="cardActionsTemplate"
        [cards]="(cardsView$ | async) | orderBy: (orderPredicate$ | async)"
        [noDataMessage]="(lisFilterManager.filters$ | async).length ? noFilteredResultsMsg : noItemsMsg"
        uniqueIdentifier="id"/>
    }

    @if (view === ListCardView.List) {
      <div class="px-3">
        <phar-list-view
          (afterClick)="clickHandler($event)"
          [itemHeight]="60"
          [parentHeight]="listTemplate.listBody.nativeElement.clientHeight"
          [noDataMessage]="(lisFilterManager.filters$ | async).length ? noFilteredResultsMsg : noItemsMsg"
          [config]="config"
          [dataSource]="(listView$ | async) | orderBy: (orderPredicate$ | async)"
        />
      </div>
    }

  </div>
</phar-list-template>


<ng-template #releaseDateTemplate let-element>
  {{ element.releaseDate | date: 'd MMM y' }}
  @if (!element.releaseDate) {
    <span class="fw-normal">-</span>
  }
</ng-template>

<ng-template #projectNameTemplate let-element>
  <span
    #projectName
    class="text-truncate"
    [pharTooltip]="element.name"
    [pharTooltipDisabled]="projectName.scrollWidth <= projectName.clientWidth">
    {{ element.name }}
  </span>
</ng-template>

<ng-template #updatedAtTemplate let-element>
  <div class="d-flex flex-column w-100">
    <div>
      {{ element.updatedAt | date: 'd MMM y' }}
    </div>
    @if (element.userIdUpdated | pharUserById: (users$ | async); as user) {
      <div class="text-truncate user-info fw-normal" [pharTooltip]="user"
           [pharTooltipDisabled]="user === 'N/A'">{{ user }}
      </div>
    }

  </div>
</ng-template>
<ng-template #createdAtTemplate let-element>
  <div class="d-flex flex-column w-100">
    <div>
      {{ element.createdAt | date: 'd MMM y' }}
    </div>

    @if (element.userIdCreated | pharUserById: (users$ | async); as user) {
      <div class="text-truncate user-info fw-normal" [pharTooltip]="user"
           [pharTooltipDisabled]="user === 'N/A'">{{ user }}
      </div>
    }
  </div>
</ng-template>

<ng-template #projectStatusTemplate let-element>
  <phar-status-badge
    [status]="element.projectStatus"
    [type]="EntityType.Project"
    class="list-card_form-status-badge">
  </phar-status-badge>
</ng-template>
<ng-template #projectVersionTemplate let-element>
  <!--  number: '0.1' ?? 'N/A'-->
  {{ element.projectVersion ?? 'N/A' }}
</ng-template>

<ng-template #cardActionsTemplate let-card>
  <ng-template
    [ngTemplateOutlet]="actionsTemplate"
    [ngTemplateOutletContext]="{$implicit: card.original}">
  </ng-template>
</ng-template>

<ng-template #actionsTemplate let-element>
  <button
    (click)="$event.stopImmediatePropagation()" [matMenuTriggerFor]="actionsMenu"
    class="ms-auto"
    mat-icon-button>
    <mat-icon class="more">more_vert</mat-icon>
  </button>

  <mat-menu #actionsMenu="matMenu" [overlapTrigger]="false">
    <button
      class="button-with-icon"
      mat-menu-item
      (click)="actionHandler({ eventName: ProjectActionsEnum.Edit, dataItem: element })">
      <mat-icon svgIcon="edit"></mat-icon>
      <span>Edit</span>
    </button>

    <button
      class="button-with-icon"
      mat-menu-item
      (click)="actionHandler({ eventName: ProjectActionsEnum.Duplicate, dataItem: element })">
      <mat-icon svgIcon="duplicate"></mat-icon>
      <span>Duplicate</span>
    </button>

    @if (element.projectStatus === ProjectStatusEnum.Released) {
      <button
        class="button-with-icon"
        mat-menu-item
        (click)="actionHandler({ eventName: ProjectActionsEnum.Amend, dataItem: element })">
        <mat-icon svgIcon="change"></mat-icon>
        <span>Amend</span>
      </button>
    }

    <button
      class="button-with-icon"
      mat-menu-item
      (click)="actionHandler({ eventName: ProjectActionsEnum.Delete, dataItem: element })">
      <mat-icon svgIcon="trash"></mat-icon>
      <span>Delete</span>
    </button>
  </mat-menu>
</ng-template>
<ng-template #cardSubtitleTemplate let-element>

  <div class="mt-2">
    Created: {{ element.createdAt | date: 'd MMM y' }} -
    @if (element.userIdCreated | pharUserById: (users$ | async); as user) {
      <span [pharTooltip]="user"
            [pharTooltipDisabled]="user === 'N/A'">{{ user |  shorten: 17: '...' }}
      </span>
    }
    @if (element.projectStatus === ProjectStatusEnum.Released) {
      <div>
        Released: {{ element.releaseDate | date: 'd MMM y' }}
      </div>
    } @else {
      <div>
        Modified: {{ element.updatedAt | date: 'd MMM y' }} -
        @if (element.userIdUpdated | pharUserById: (users$ | async); as user) {
          <span [pharTooltip]="user"
                [pharTooltipDisabled]="user === 'N/A'">{{ user  | shorten: 17: '...' }}
        </span>
        }
      </div>
    }
  </div>

</ng-template>

<ng-template #releaseDateTemplate let-element>
  {{ element.releaseDate | date: 'd MMM y' }}
</ng-template>

<ng-template #studyIdTemplate let-element>
  {{ element.id }}
</ng-template>


<ng-template #sponsorTemplate let-element>
  -
</ng-template>

