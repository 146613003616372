<form [formGroup]="form$ | async" id="schedule-editor-form">
  <div>
    <h2 class="mb-2">General</h2>
    <div class="d-flex flex-column">
      <mat-label class="required-field">Event Name</mat-label>

      <mat-form-field>
        <input [maxlength]="50" [pharMaxLengthTooltip]="50"
               formControlName="eventName"
               matInput
               pharInputTrim
               placeholder="Name"
        />
        <mat-error>
          @if (form.controls.eventName.hasError('required')) {
            Event name is <strong>required</strong>
          } @else if (form.controls.eventName.hasError('notUnique')) {
            Event name must be <b>unique</b>
          }
        </mat-error>
      </mat-form-field>
    </div>

    <mat-label class="required-field"> Event Availability</mat-label>
    <mat-form-field class="w-100">
      <mat-select formControlName="eventAvailability" placeholder="Select event availability">
        @for (option of eventAvailabilityDisplayOptions; track $index) {
          <mat-option
            [value]="option.value">
            {{ option.label }}
          </mat-option>
        }
      </mat-select>

      <mat-error>
        Event availability is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    @if ((currentEvent$ | async).eventAvailability === EventAvailabilityTypeEnum.DuringEnrollment) {
      <mat-label class="required-field">Event Type</mat-label>
      <mat-form-field class="w-100">
        <mat-select formControlName="elementType" placeholder="Select element type">
          <mat-select-trigger>
            <div class="d-flex align-items-center">
              <mat-icon class="me-2 element-type-icon" [svgIcon]="selectedElementType.icon"></mat-icon>
              {{ selectedElementType.label }}
            </div>

          </mat-select-trigger>
          @for (option of elementTypeDisplayOptions; track $index) {
            <mat-option
              [disabled]="option.disabled"
              [value]="option.value">
              <mat-icon [svgIcon]="option.icon"></mat-icon>
              {{ option.label }}
            </mat-option>
          }
        </mat-select>

        <mat-error>
          Element type is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <h2 class="mb-2">Scheduling</h2>
      <div class="row gx-0 mb-1">
        <div class="d-flex flex-column col-6 pe-2">
          <mat-label class="required-field">Start</mat-label>
          <mat-form-field>
            <input [allowNegatives]="true"
                   [maxAllowedCharacters]="8"
                   [max]="9999"
                   [min]="-9999"
                   formControlName="start"
                   matInput
                   pharAllowOnlyNumber
                   placeholder="Start"/>
            <mat-error>
              Start is <b>required</b>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="d-flex flex-column col-6 ps-2">
          <mat-label class="required-field">Unit</mat-label>
          <mat-form-field>
            <mat-select formControlName="unit" placeholder="Select unit type">
              @for (option of timeUnitDisplayOptions; track $index) {
                <mat-option [value]="option.value">
                  {{ option.label }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="d-flex justify-content-between align-items-center overflow-hidden">
        <div class="d-flex align-items-center">
          <button
            mat-icon-button
            class="text-left"
            [disabled]="disabled"
            (click)="toggleFields('hasSpecificTime')">
            <mat-icon [svgIcon]="form.get('hasSpecificTime').value ? 'arrow-down' : 'arrow-right'"></mat-icon>
          </button>
          <h3 class="mb-0">Specific time</h3>
        </div>
        <mat-slide-toggle color="primary" formControlName="hasSpecificTime">
        </mat-slide-toggle>
      </div>
      @if (form.get('hasSpecificTime').value) {
        <mat-form-field>
          <phar-timepicker-field [showSeconds]="hasSpecificTimeSeconds"
                                 [withPeriod]="hasSpecificTimePeriod"
                                 [autoNavigateToMinutes]="true"
                                 formControlName="specificTime"></phar-timepicker-field>
          <mat-icon matSuffix>access_time</mat-icon>

          <mat-error>
            Specific time is <b>required</b>
          </mat-error>
        </mat-form-field>
      }

      <h2 class="mb-2">Event Window</h2>
      <div class="row gx-0">
        <div class="d-flex flex-column col-6 pe-2">
          <mat-label>Before</mat-label>
          <mat-form-field>
            <input formControlName="beforeDeviation" matInput placeholder="Before" type="number"/>
          </mat-form-field>
        </div>

        <div class="d-flex flex-column col-6 ps-2">
          <mat-label>Unit</mat-label>
          <mat-form-field>
            <mat-select formControlName="beforeDeviationUnit" placeholder="Select unit type">
              @for (option of timeUnitDisplayOptions; track $index) {
                <mat-option [value]="option.value">
                  {{ option.label }}
                </mat-option>
              }

            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row gx-0">
        <div class="d-flex flex-column col-6 pe-2">
          <mat-label>After</mat-label>
          <mat-form-field>
            <input formControlName="afterDeviation" matInput placeholder="After" type="number"/>
          </mat-form-field>
        </div>

        <div class="d-flex flex-column col-6 ps-2">
          <mat-label>Unit</mat-label>
          <mat-form-field>
            <mat-select formControlName="afterDeviationUnit" placeholder="Select unit type">
              @for (option of timeUnitDisplayOptions; track $index) {
                <mat-option [value]="option.value">
                  {{ option.label }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center overflow-hidden">
        <div class="d-flex align-items-center">
          <button
            class="text-left"
            mat-icon-button
            [disabled]="disabled"
            (click)="toggleFields('isRepeatable')">
            <mat-icon
              [svgIcon]="form.get('isRepeatable').value ? 'arrow-down': 'arrow-right'"></mat-icon>
          </button>
          <h3 class="mb-0">Recurring Event</h3>
        </div>
        <mat-slide-toggle color="primary" formControlName="isRepeatable">
        </mat-slide-toggle>
      </div>
      @if (form.get('isRepeatable').value) {
        <div class="row gx-0">
          <div class="d-flex flex-column col-6 pe-2">
            <mat-label class="required-field">Repeat every</mat-label>
            <mat-form-field>
              <input formControlName="repeatEvery" matInput placeholder="Repeat every" type="number" pharAllowOnlyNumber
                     [allowNegatives]="false" [min]="0"/>
            </mat-form-field>
          </div>

          <div class="d-flex flex-column col-6 ps-2">
            <mat-label class="required-field">Unit</mat-label>
            <mat-form-field>
              <mat-select formControlName="repeatEveryUnit" placeholder="Select unit type">
                @for (option of timeUnitDisplayOptions; track $index) {
                  <mat-option [value]="option.value">
                    {{ option.label }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row gx-0">
          <div class="d-flex flex-column col-6 pe-2">
            <mat-label class="required-field">Ends after</mat-label>
            <mat-form-field>
              <input formControlName="endsAfter" matInput placeholder="Ends after" type="number" pharAllowOnlyNumber
                     [allowNegatives]="false" [min]="0"/>
            </mat-form-field>
          </div>

          <div class="d-flex flex-column col-6 ps-2">
            <mat-label class="required-field">Unit</mat-label>
            <mat-form-field>
              <mat-select formControlName="endsAfterUnit" placeholder="Select unit type">
                @for (option of timeUnitDisplayOptions; track $index) {
                  <mat-option [value]="option.value">
                    {{ option.label }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>

          @if (repeatTitlesFormArray?.controls?.length) {
            <div class="row gx-0">
              <div class="col-12" formArrayName="repeatTitles">
                @for (repeatMessage of repeatTitlesFormArray.controls; track index; let index = $index) {
                  <mat-label>Event Title ({{ index + 1 }})</mat-label>
                  <mat-form-field class="col-12">
                    <input [formControlName]="index" matInput placeholder="Event Title" type="text"/>
                    <mat-error>Event title is <b>required</b></mat-error>
                  </mat-form-field>
                }
              </div>
            </div>
          }
        </div>

      }
    }
  </div>
</form>
<mat-toolbar id="editor-actions" class="background-transparent d-flex justify-content-between p-0 column-gap-3">
  <button
    class="ms-auto btn-small"
    mat-raised-button
    [disabled]="(hasPendingChanges$ | async) === false"
    (click)="discardChanges()">
    <mat-icon [svgIcon]="'close'"></mat-icon>
    <b>Discard</b>
  </button>

  <button
    mat-raised-button
    class="ms-auto button-success flex-1"
    [disabled]="(hasPendingChanges$ | async) === false"
    (click)="addEditEventSettings()">
    <div class="d-flex align-items-center">
      @if (isNewEvent) {
        <mat-icon [svgIcon]="'plus'" class="me-2"></mat-icon>
        <b>Save</b>
      } @else {
        <mat-icon [svgIcon]="'check'" class="me-2"></mat-icon>
        <b>Update</b>
      }
    </div>
  </button>
</mat-toolbar>
