<div id="file-upload-container" [ngClass]="{'p-1': uploadedImage, 'cursor-pointer': !disabled}" pharDnd
     (click)="imageUpload.click()"
     [disabled]="disabled"
     (fileDropped)="fileChange($event)">
  @if (uploadedImage) {
    <img class="preview-image" [src]="uploadedImage" alt="logo"/>
  } @else if (imageSrc) {
    <img class="preview-image" [src]="imageSrc" alt="logo"/>
  } @else {
    <img src="assets/images/image-placeholder.svg" alt="logo">
    <div class="fw-bold mt-2"> {{ title }}</div>
    <div id="hint">
      {{ subtitle }}
    </div>
  }

</div>

<input class="d-none" #imageUpload type="file" [disabled]="disabled" (change)="fileChange($event.target.files)"/>
