import {
  AfterViewInit,
  Component, ElementRef,
  EventEmitter, HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ElementTypeEnum } from '../enums/element-type.enum';
import { Observable } from 'rxjs';
import { EventGroupModel, EventModel } from '../event.model';
import { DraggableListViewConfigModel } from '../../shared/draggable-list-view/draggable-list-view-model';
import { AppState, getState } from '../../store/models/app.state';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { ProjectEventModel } from '../project-event.model';
import { ListViewColumnModel } from '../../shared/list-view/list-view-column.model';
import { UtilsService } from '../../core/utils.service';
import { EventActions } from '../enums/event-actions.enum';
import {
  changeCurrentProjectEvent,
  createProjectEvent,
  deleteProjectEvent,
  dispatchedCreateUpdateEvent,
  loadEventNotificationsListByEventId,
  loadProjectEventListByProjectId,
  pendingCreateUpdateEvent,
  resetCurrentProjectEvent,
  resetEventNotificationsList,
  successCreateUpdateEvent,
  updateProjectEvent
} from '../store/event.actions';
import {
  CurrentEventManage,
  selectCurrentEventProjectEvent,
  selectListEventNotificationsState
} from '../store/event.state';
import { orderBy } from 'lodash-es';
import { PharConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog-service.service';
import { ADHOC_EVENTS_TYPES } from '../../core/config/app.constants';
import { BaseComponent } from '../../shared/base.class';
import { EventsEditorTabEnum } from '../events/events.component';
import { environment } from '../../../environments/environment';
import { ResizingEvent } from '../../shared/models/resizing-event.interface';
import { EventCommentDialogComponent } from '../event-comments/event-comment.dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { COMMENTS_DIALOG_CONFIG } from '../../shared/entity-comments/entity-comment-base/base-comments-component.config';

@Component({
  selector: 'phar-adhoc-events-list',
  templateUrl: 'adhoc-events-list.component.html',
  styleUrls: ['adhoc-events-list.component.scss']
})

export class AdhocEventsListComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('elementTypeTemplate', { static: true }) elementTypeTemplate: TemplateRef<any>;
  @ViewChild('actionsTemplate', { static: true }) actionsTemplate: TemplateRef<any>;
  @Input() isLocked = false;
  @ViewChild('eventsContainer', { static: true }) eventsContainer: ElementRef;
  @Output() duplicateEvent: EventEmitter<EventModel> = new EventEmitter<EventModel>();
  private readonly rightBarMinWidth = 270;
  private readonly rightBarMaxWidth = window.innerWidth / 2;

  @HostListener('window:mousemove', ['$event'])
  private updateSidebarWidth(event: MouseEvent) {
    if (!this.rightBarResizingEvent.isResizing) {
      return;
    }

    const cursorDeltaX = this.rightBarResizingEvent.startingCursorX - event.clientX;
    const newWidth = this.rightBarResizingEvent.startingWidth + cursorDeltaX;

    this.setRightBarWidth(newWidth);
  }

  @HostListener('window:mouseup')
  private stopSidebarResizing() {
    this.rightBarResizingEvent.isResizing = false;
  }

  rightBarResizingEvent: ResizingEvent = {
    isResizing: false,
    startingCursorX: 0,
    startingWidth: 0,
  };
  isEditorOpened = false;
  projectId: number = 0;
  selectedEventId: number;
  activeEditorTab: EventsEditorTabEnum = EventsEditorTabEnum.Properties;
  adhocEventsList$: Observable<EventModel[]>;
  numberOfEventNotifications$: Observable<number>;
  selectedEvent$: Observable<EventModel>;
  readonly rowActionBtns = [
    {
      title: 'Edit',
      eventName: EventActions.Edit,
      icon: 'edit'
    },
    {
      title: 'Duplicate',
      eventName: EventActions.Duplicate,
      icon: 'duplicate',
    },
    {
      title: 'Comments',
      eventName: EventActions.Comments,
      icon: 'flag',
    },
    {
      title: 'Delete',
      eventName: EventActions.Delete,
      icon: 'trash'
    },
  ];
  readonly config: DraggableListViewConfigModel = {
    columns: [
      {
        field: 'id',
        size: '0',
        title: 'ID',
        show: false,
        hasTemplate: false,
        template: null
      },
      {
        field: 'expand',
        size: '40px',
        title: '',
        show: true,
        hasTemplate: false,
        template: null
      },
      {
        field: 'eventName',
        title: 'Name',
        size: '1fr',
        show: true,
        order: false,
        hasTemplate: false,
        selectable: true,
        template: null
      },
      {
        field: 'elementType',
        title: 'Type',
        size: '1fr',
        show: true,
        order: false,
        hasTemplate: true,
        selectable: true,
        template: null
      },
      {
        field: 'userIdCreated', // field is used only for placeholder
        title: 'Actions',
        size: '50px',
        // Add as many pixels as left margin added for nested columns (2rem).
        // sizeNested: '86px',
        show: true,
        hasTemplate: true,
        selectable: true,
        template: null
      },
    ],
    actions: [],
    customClasses: []

  };
  protected readonly ElementType = ElementTypeEnum;
  protected readonly EventsEditorTabEnum = EventsEditorTabEnum;
  private eventState$: Observable<CurrentEventManage>;


  constructor(
    private store: Store<AppState>,
    private utilsService: UtilsService,
    private confirmationService: PharConfirmDialogService,
    private dialog: MatDialog,
  ) {
    super();
  }

  ngOnInit() {
    this.selectedEventId = getState(this.store).event.current.projectEvent.event.id;

    this.store.pipe(
      select(state => state.project.current.project.id),
      filter(id => !!id),
      takeUntil(this.destroy$)
    ).subscribe(id => {
      this.projectId = id;
      this.store.dispatch(loadProjectEventListByProjectId({ id: id }));
    })

    this.adhocEventsList$ = this.store.select(state => state.event.listProjectEvents).pipe(
      map((projectEventList) => {
        return projectEventList
          .filter((projectEvent: ProjectEventModel) => projectEvent.event !== null)
          .map((projectEvent: ProjectEventModel) => ({ ...projectEvent.event }))

      }),
      map((events: EventModel[]) => {
        return events.filter((event: EventModel) => ADHOC_EVENTS_TYPES.includes(event.elementType))
      }),
      map((events: EventModel[]) => {
        return orderBy(events, ['id'], 'asc');
      })
    );
    this.eventState$ = this.store.select(state => state.event.current);
    this.selectedEvent$ = this.store.select(selectCurrentEventProjectEvent);
    this.numberOfEventNotifications$ = this.store.select(selectListEventNotificationsState)
      .pipe(map((eventNotifications) => eventNotifications.length));

    this.loadEventNotificationsOnSelectEvent();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngAfterViewInit() {
    const templates = {
      elementType: this.elementTypeTemplate,
      userIdCreated: this.actionsTemplate,
    };
    if (this.isLocked) {
      delete templates.userIdCreated;
      this.config.columns = this.config.columns.filter(item => item.field !== 'userIdCreated');
    }
    this.config.columns = this.utilsService.setColumnTemplate<ListViewColumnModel>(this.config.columns, templates, 'field');
    if (this.selectedEventId) {
      this.scrollToElement(this.selectedEventId);
    }
  }

  addNewEvent() {
    if (this.isLocked) {
      return;
    }
    this.selectedEventId = null;
    this.store.dispatch(resetCurrentProjectEvent());
    this.openEditor();
  }

  handleSave() {
    let projectEvent: ProjectEventModel = {
      ...getState(this.store).event.current.projectEvent,
    };

    this.store.dispatch(pendingCreateUpdateEvent({ pending: true }));
    this.store.dispatch(dispatchedCreateUpdateEvent({ dispatched: true }));
    if (projectEvent.id === null) {
      projectEvent = {
        ...projectEvent,
        projectId: this.projectId,
        event: {
          ...projectEvent.event,
          start: 0,
        }
      }
      this.store.dispatch(createProjectEvent({ projectEvent }));
    } else {
      this.store.dispatch(updateProjectEvent({ projectEvent }));
    }

    this.eventState$.pipe(
      filter(({ dispatched, success }) => dispatched && success),
      tap((state: CurrentEventManage) => {
        this.store.dispatch(pendingCreateUpdateEvent({ pending: false }));
        this.store.dispatch(successCreateUpdateEvent({ success: false }));
        this.closeEditor()
      }),
      take(1),
    ).subscribe();
  }

  openEditor() {
    this.isEditorOpened = true;
  }

  closeEditor() {
    this.isEditorOpened = false;
    this.selectedEventId = null;
    this.activeEditorTab = EventsEditorTabEnum.Properties;
    this.store.dispatch(resetCurrentProjectEvent());
    this.eventsContainer.nativeElement.style.removeProperty('--editor-width');
  }


  actionHandler($event: { eventName: EventActions, dataItem: EventGroupModel }): void {
    const { eventName, dataItem } = $event;
    switch (eventName) {
      case EventActions.Edit:
        this.handleEdit(dataItem.id)
        break;
      case EventActions.Delete:
        this.handleDelete(dataItem.eventName, dataItem.id);
        break;
      case EventActions.Duplicate:
        this.handleDuplicateEvent(dataItem);
        break;
      case EventActions.Comments:
        this.openCommentsDialog(dataItem);
        break;
      default:
        break;
    }
  }

  openCommentsDialog(event: EventModel): void {
    this.dialog.open(EventCommentDialogComponent, {
      data: {
        eventId: event.id
      },
      ...COMMENTS_DIALOG_CONFIG
    })
  }

  setActiveEditorTab(tab: EventsEditorTabEnum): void {
    this.activeEditorTab = tab;
  }

  handleEdit(id: number): void {
    this.selectedEventId = id;
    this.store.dispatch(changeCurrentProjectEvent({ eventId: id }));
    this.openEditor();
  }

  startRightBarResizing(event: MouseEvent): void {
    this.rightBarResizingEvent = {
      isResizing: true,
      startingCursorX: event.clientX,
      startingWidth: this.getRightBarWidth(),
    };
  }

  scrollToElement(elementId: number, prefix = 'item-'): void {
    try {
      this.utilsService.waitForElementToExist(`#${prefix}${elementId}`).then((element: HTMLElement) => {

        (element as HTMLElement).scrollIntoView({ behavior: 'smooth' });

      })
    } catch (e) {
      if (!environment.production) {
        console.warn(e);
      }
    }

  }


  columnsSelectionChange(columns: ListViewColumnModel[]): void {
    this.config.columns = columns;
  }

  private loadEventNotificationsOnSelectEvent(): void {
    this.selectedEvent$
      .pipe(
        map((selectedEvent) => selectedEvent.id),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
      )
      .subscribe((selectedEventId) => {
        this.setActiveEditorTab(EventsEditorTabEnum.Properties);
        if (selectedEventId) {
          this.store.dispatch(loadEventNotificationsListByEventId({ id: selectedEventId }));
        } else {
          this.store.dispatch(resetEventNotificationsList());
        }
      });
  }

  private handleDelete(name: string, id: number): void {
    this.confirmationService.openConfirmDialog('Do you want to remove ' + name + '?')
      .pipe(
        filter((result) => !!result),
        take(1)
      ).subscribe((result) => {
      const projectEventId = getState(this.store).event.listProjectEvents.find(x => x.eventId == id).id;
      const currentsSelected = getState(this.store).event.current.projectEvent.id;
      if (projectEventId === currentsSelected) {
        this.store.dispatch(resetCurrentProjectEvent());
        this.closeEditor();

      }
      this.store.dispatch(deleteProjectEvent({ id: projectEventId }));
    });
  }

  private handleDuplicateEvent(event: EventGroupModel): void {
    this.duplicateEvent.emit(event);
  }


  private getRightBarWidth(): number {
    return parseInt(
      getComputedStyle(this.eventsContainer.nativeElement).getPropertyValue('--editor-width'),
      10,
    );
  }

  private setRightBarWidth(width: number) {
    const clampedWidth = Math.min(
      Math.max(width, this.rightBarMinWidth),
      this.rightBarMaxWidth,
    );

    this.eventsContainer.nativeElement.style.setProperty('--editor-width', `${clampedWidth}px`);
    window.dispatchEvent(new Event('resize'));
  }
}
